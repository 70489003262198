import "./DbrdPassengers.scss";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import { AVIColorScales } from "../../chart-components/AVIColorScales";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { CrossfilterRecord } from "../../custom";
import { SimpleTimespan } from "../../filters/SimpleDateTimeControl/SimpleTimespan";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader, SuperagentProgress } from "../../ui-components/Loader/Loader";
import { getCountryForShipFlagCode } from "../../utils/codelists";

export type DbrdPassengersProps = {
  location: any;
};

export type DbrdPassengersState = {
  chartData?: any;
  progressData?: SuperagentProgress;
  errorData?: any;
};

class DbrdPassengersLocal extends Component<
  DbrdPassengersProps,
  DbrdPassengersState
> {
  static dashboardRoute = "passengers";

  static dashboardFilters(filter, setFilter, passlines, renderSelection) {
    return {
      helpMessage: "Passasjerer genererer mye data",
      controls: [
        <SimpleTimespan
          key="flt-timespan"
          filter={filter}
          setFilter={setFilter}
        />,
      ],
    };
  }

  static dashboardSettings() {
    return {};
  }

  static dashboardValidation(filter) {
    if (filter.fromTime && filter.toTime) {
      return true;
    } else {
      return false;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
    };

    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    const { fromTime, toTime } = this.props.location.state;
    try {
      DataLoader.getApi(
        `/api/voyage/passengers/by-voyage-segments/${fromTime}/${toTime}`,
        {},
        this.reportProgress
      )
        .then((res) => {
          // Check that all responses are successful
          // console.log(res);
          if (!res.success) throw new Error("Could not load analytics data");

          if (Array.isArray(res.data) && res.data.length > 0) {
            res.data.forEach((element) => {
              element.created = new Date(element.created);
            });
            this.setState({
              chartData: crossfilter(res.data),
            });
          }
        })
        .catch((error) => {
          console.warn(error);
          this.setState({
            chartData: crossfilter([]),
          });
        });
    } catch (error) {
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData: SuperagentProgress) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, progressData } = this.state;
    
    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }
    
    const { fromTime, toTime } = this.props.location.state;

    var dashboardTitle =
      "Passasjerer for Cruiseankomster til Norske Lokasjoner";

    function isNorway(ct) {
      return ["NORGE", "SVALBARD OG JAN MAYEN"].indexOf(ct) > -1;
    }

    var dimDomesticInternational = chartData.dimension(
      (d: CrossfilterRecord) => {
        if (isNorway(d.ocountry) && !isNorway(d.dcountry)) {
          return "Seilas fra Norge til utlandet";
        } else if (!isNorway(d.ocountry) && isNorway(d.dcountry)) {
          return "Seilas til Norge fra utlandet";
        } else if (isNorway(d.dcountry) && isNorway(d.ocountry)) {
          return "Seilas mellom lokasjoner i Norge";
        }
      }
    );
    var grpVoyagesDomesticInternational = dimDomesticInternational
      .group()
      .reduceSum((d: CrossfilterRecord) => d.passengers);

    var dimSegment = chartData.dimension((d: CrossfilterRecord) => [
      d.origin,
      d.destination,
    ]);

    var dimOrigin = chartData.dimension((d: CrossfilterRecord) => d.origin);
    var grpPassengersByOrigin = dimOrigin
      .group()
      .reduceSum((d: CrossfilterRecord) => d.passengers);
    var grpVoyagesByOrigin = dimOrigin.group().reduceCount();

    var dimDestination = chartData.dimension(
      (d: CrossfilterRecord) => d.destination
    );
    var grpPassengersByDestination = dimDestination
      .group()
      .reduceSum((d: CrossfilterRecord) => d.passengers);
    var grpVoyagesByDestination = dimDestination.group().reduceCount();

    var dimNationality = chartData.dimension(
      (d: CrossfilterRecord) => d.countryofnationalitynamenor
    );
    var grpPassengersByNationality = dimNationality
      .group()
      .reduceSum((d: CrossfilterRecord) => d.passengers);

    var dimFlag = chartData.dimension(
      (d: CrossfilterRecord) =>
        `${d.flag} - ${getCountryForShipFlagCode(d.flag) ?? ""}`
    );
    var grpPassengersByShipFlag = dimFlag
      .group()
      .reduceSum((d: CrossfilterRecord) => d.passengers);

    var dimMonth = chartData.dimension((d: CrossfilterRecord) => d.month);
    var grpPassengersByMonth = dimMonth
      .group()
      .reduceSum((d: CrossfilterRecord) => d.passengers);

    return (
      <div className="AppView">
        <AVIDashboard
          title={dashboardTitle}
          desc={`Data om passasjerer etter nasjonalitet, avgangs og ankomsthavner fra SafeSeaNet i tidsrommet ${fromTime} til ${toTime}. Datasettet inkludere bare ankomster til lokasjoner i Norge. Avvik i antall avganger og ankomster forklares ved at avganger fra Norske lokasjoner til utlandet er utelatt`}
          cfilter={chartData}
          filter={this.props.location.state}
          useFlex
          cmsSlug={DbrdPassengersLocal.dashboardRoute}
        >
          <AVIRow>
            <RowChart
              chartTitle="Passasjerer etter innenriks/utenriks"
              colorAccessor={() => "A"}
              height={0.75}
              dimension={dimDomesticInternational}
              group={grpVoyagesDomesticInternational}
              filterPrefix="Internasjonalt"
              colors={AVIColorScales.colorScaleKyv2024Mono}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Avgangslokasjon etter antall passasjerer (15 største)"
              colorAccessor={() => "A"}
              height={2}
              dimension={dimOrigin}
              group={GroupUtils.RemoveEmptyBinsTopN(grpPassengersByOrigin, 15)}
              filterPrefix="Avgangslokasjon"
              colors={AVIColorScales.colorScaleKyv2024Mono}
              useFlex
            />
            <RowChart
              chartTitle="Norske ankomstlokasjoner etter antall passasjerer (15 største)"
              height={2}
              colorAccessor={() => "A"}
              dimension={dimDestination}
              group={GroupUtils.RemoveEmptyBinsTopN(
                grpPassengersByDestination,
                15
              )}
              colors={AVIColorScales.colorScaleKyv2024Mono}
              filterPrefix="Anokmstlokasjon"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Avgangslokasjon etter antall seilas (15 største)"
              colorAccessor={() => "A"}
              width={2}
              height={2}
              dimension={dimOrigin}
              group={GroupUtils.RemoveEmptyBinsTopN(grpVoyagesByOrigin, 15)}
              colors={AVIColorScales.colorScaleKyv2024Mono}
              filterPrefix="Avgangslokasjon"
              useFlex
            />
            <RowChart
              chartTitle="Norske ankomstlokasjoner etter antall seilas (15 største)"
              width={2}
              height={2}
              colorAccessor={() => "A"}
              dimension={dimDestination}
              group={GroupUtils.RemoveEmptyBinsTopN(
                grpVoyagesByDestination,
                15
              )}
              colors={AVIColorScales.colorScaleKyv2024Mono}
              filterPrefix="Anokmstlokasjon"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(
                grpPassengersByNationality,
                50
              )}
              dimension={dimNationality}
              chartTitle="Antall passasjerer etter nasjonalitet (50 største)"
              width={4}
              height={2}
              useFlex
              colors={AVIColorScales.colorScaleKyv2024Mono}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d: CrossfilterRecord) => -d.value}
              xAxisLabel="Nasjonalitet"
              filterPrefix="Nasjonalitet"
              rotateXAxisLabels={-20}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={grpPassengersByMonth}
              dimension={dimMonth}
              chartTitle="Antall passasjerer etter måned i året"
              useFlex
              height={2}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d: CrossfilterRecord) => d.key}
              xAxisLabel="Måned i året"
              filterPrefix="Nasjonalitet"
              colors={AVIColorScales.colorScaleKyv2024Mono}
              rotateXAxisLabels={-15}
            />
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Passasjerer etter skipets flaggstat (15 største)"
              width={2}
              height={2}
              dimension={dimFlag}
              group={GroupUtils.RemoveEmptyBinsTopN(
                grpPassengersByShipFlag,
                15
              )}
              filterPrefix="Flaggstat"
              colors={AVIColorScales.colorScaleKyv2024}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Passasjerer etter år, måned, avgangs-/ankomststed og nasjonalitet"
              useFlex
              columns={[
                {
                  label: "År",
                  format: (d: CrossfilterRecord) => d.year,
                },
                {
                  label: "Måned",
                  format: (d: CrossfilterRecord) => d.month,
                },
                {
                  label: "Avgangslokasjon",
                  format: (d: CrossfilterRecord) => d.origin,
                },
                {
                  label: "Avgangsland",
                  format: (d: CrossfilterRecord) => d.ocountry,
                },
                {
                  label: "Ankomstlokasjon",
                  format: (d: CrossfilterRecord) => d.destination,
                },
                {
                  label: "Ankomstland",
                  format: (d: CrossfilterRecord) => d.dcountry,
                },
                {
                  label: "Flaggstat",
                  format: (d: CrossfilterRecord) => d.flag,
                },
                {
                  label: "Nasjonalitet",
                  format: (d: CrossfilterRecord) =>
                    d.countryofnationalitynamenor,
                },
                {
                  label: "Passasjerer",
                  format: (d: CrossfilterRecord) => d.passengers,
                },
              ]}
              dimension={dimSegment}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdPassengers = withRouter(DbrdPassengersLocal);

export default DbrdPassengers;
