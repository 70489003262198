import * as d3 from "d3";
import reductio from "reductio";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartHistogram } from "../../chart-components/Charts/BarChartHistogram";
import { BoxPlot } from "../../chart-components/Charts/BoxPlot";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { PointDimMapChart } from "../../chart-components/ChartsExt/PointDimMapChart";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { SimpleTimespan } from "../../filters/SimpleDateTimeControl/SimpleTimespan";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { SelectLocationsInMap } from "../../ui-components/filter-view/FilterMap/SelectLocationsInMap";

class DbrdTimeInPortOSPSBase extends Component<any, any> {
  /**
   * The route to be used for the dashboard
   */
  static dashboardRoute = "tid-i-havn-osps";

  /**
   * The help text and filter controls to be used as initial filtering for the dashboard
   * @param {*} filter
   * @param {*} setFilter
   */
  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å analysere flere år samtidig. Velg en eller flere avgangs- eller ankomsthavner og f.eks. tidsrommet 2017-2018. Dette dashboardet viser alle historiske ankomster for skip som er registrerte med ESI og har OPS i dag - uten å ta hensyn til når de fikk dette installert.",
      controls: [
        <SimpleTimespan
          key="flt2"
          filter={filter}
          minAge={4}
          setFilter={setFilter}
        />,
        <SelectLocationsInMap
          key="sel-loc"
          name="locationIds"
          returnProperty="id"
        />,
      ],
    };
  }

  /**
   * The dashboard settings, i.e. map interaction filter controls, selectable layers etc.
   */
  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  /**
   * The dashboard validation function
   * @param {*} filter
   */
  static dashboardValidation(filter) {
    if (
      filter.fromTime &&
      filter.toTime &&
      Array.isArray(filter.locationIds) &&
      filter.locationIds.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  /**
   * TestRoute
   *
   * @param {*} props An object looking like this {a:, b:}
   */
  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      locationData: [],
    };
  }

  componentDidMount() {
    try {
      const { locationIds, fromTime, toTime } = this.props.location.state;

      if (Array.isArray(locationIds) && fromTime && toTime) {
        DataLoader.post(
          process.env.REACT_APP_DASHBOARD_WS_API +
            "/api/location/time-in-port/osps",
          {
            LocationIds: locationIds,
            StartDate: fromTime,
            EndDate: toTime,
          }
        )
          .then((res) => {
            // Convert ETA/ETD date properties from data type string to Date
            res.data.forEach((rec) => {
              rec.eta = new Date(rec.eta);
              rec.etd = new Date(rec.etd) || "";
            });

            this.setState({
              chartData: crossfilter(res.data),
            });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: crossfilter([]),
            });
          });
      } else {
        throw new Error("Missing dashboard selection parameters");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  render() {
    const { chartData } = this.state;
    const { fromTime, toTime } = this.props.location.state;

    if (!chartData || chartData.size() === 0) {
      return <Loader chartData={chartData} />;
    }

    var hoursReducer = (reductio() as any)
      .count(true)
      .sum(function (d) {
        return d.hours;
      })
      .avg(true);

    var avgHoursReducer = (reductio() as any).avg(function (d) {
      return d.hours;
    });

    var dimLocation = chartData.dimension((d) => d.name || "");
    var portCallsByLocation = dimLocation.group().reduceCount();

    var dimFylke = chartData.dimension((d) => d.fylke || "");
    var portCallsByFylke = dimFylke.group().reduceCount();

    var dimKommune = chartData.dimension((d) => d.kommune || "");
    var portCallsByKommune = dimKommune.group().reduceCount();

    var avgHoursByLocation = dimLocation.group();
    hoursReducer(avgHoursByLocation);

    var dimShipCategory = chartData.dimension((d) => d.shipcategory || "");
    var arrivalsByShipCategory = dimShipCategory.group().reduceCount();

    var dimShipType = chartData.dimension((d) => d.shiptype || "");
    var arrivalsByShipType = dimShipType.group().reduceCount();

    var dimWeekDayHour = chartData.dimension((d) => [
      TimeUtils.getLocalMonthNameAbb(d.eta),
      d.name,
    ]);
    var hoursByWeekDayHour = dimWeekDayHour.group();
    avgHoursReducer(hoursByWeekDayHour);

    var dimId = chartData.dimension((d) => d.id || "");

    var dimLocation2 = chartData.dimension((d) => d.name);

    var dimLonLat = chartData.dimension((d) => [d.long, d.lat, d.name]);
    var arrivalsByLonLat = dimLonLat.group().reduceCount();

    var timeInPortByLocationBox = dimLocation2.group().reduce(
      function (p, v) {
        p.splice(d3.bisectLeft(p, v.hours), 0, v.hours);
        return p;
      },
      function (p, v) {
        p.splice(d3.bisectLeft(p, v.hours), 1);
        return p;
      },
      function () {
        return [];
      }
    );

    return (
      <div className="AppView">
        <AVIDashboard
          title="Tid i havn for skip med landstrømsanlegg"
          desc={`Statistikk over tid i havn for registerpliktige ankomster til valgte lokasjoner for skip med landstrømanlegg i tidsrommet ${TimeUtils.toCompactDate(
            new Date(fromTime)
          )} - ${TimeUtils.toCompactDate(new Date(toTime))}.`}
          spacing={20}
          group={chartData.groupAll()}
          units="opphold i havn"
          useFlex
        >
          <AVIRow>
            <PointDimMapChart
              chartTitle="Antall ankomster etter sted"
              height={1.5}
              dimension={dimLonLat}
              group={arrivalsByLonLat}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartHistogram
              chartTitle="Antall ankomster etter tid i havn (timer)"
              chartCrossfilter={chartData}
              useFlex
              height={1.5}
              xAxisLabel={"Timer i havn"}
              yAxisLabel={"Antall ankomster"}
              histogramValueAccessor={(d) => d.hours}
              thresholds={[
                0, 0.5, 1, 2, 3, 6, 12, 24, 48, 72, 96, 168, 336, 672, 100000,
              ]}
            />
          </AVIRow>
          <AVIRow>
            <AVICol>
              <RowChart
                chartTitle="Gjennomsnittlig antall timer i havn etter lokasjon (inntil 15 største)"
                dimension={dimLocation}
                group={GroupUtils.RemoveEmptyBinsTopN(avgHoursByLocation, 15)}
                useFlex
                height={1.5}
                valueAccessor={(d) => Math.round(d.value.avg * 10) / 10}
                ordering={(d) => -d.value.avg}
                filterKey="Lokasjon"
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Totalt antall timer i havn etter lokasjon (inntil 15 største)"
                dimension={dimLocation}
                group={GroupUtils.RemoveEmptyBinsTopN(avgHoursByLocation, 15)}
                useFlex
                height={1.5}
                valueAccessor={(d) => Math.round(d.value.sum * 10) / 10}
                ordering={(d) => -d.value.sum}
                filterKey="Lokasjon"
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            {portCallsByKommune.all().length > 1 && (
              <AVICol>
                <RowChart
                  chartTitle="Anløp etter fylke (inntil 5 største)"
                  dimension={dimFylke}
                  group={GroupUtils.RemoveEmptyBinsTopN(portCallsByFylke, 5)}
                  useFlex
                  height={1.5}
                  ordering={(d) => -d.value}
                  filterKey="Fylke"
                />
              </AVICol>
            )}
            {portCallsByKommune.all().length > 1 && (
              <AVICol>
                <RowChart
                  chartTitle="Anløp etter kommune (inntil 10 største)"
                  dimension={dimKommune}
                  group={GroupUtils.RemoveEmptyBinsTopN(portCallsByKommune, 10)}
                  useFlex
                  height={1.5}
                  ordering={(d) => -d.value}
                  filterKey="Kommune"
                />
              </AVICol>
            )}
          </AVIRow>
          <AVIRow>
            <AVICol>
              <RowChart
                chartTitle="Antall ankomster etter lokasjon (inntil 10 største)"
                dimension={dimLocation}
                group={GroupUtils.RemoveEmptyBinsTopN(portCallsByLocation, 10)}
                useFlex
                height={1}
                valueAccessor={(d) => d.value}
                ordering={(d) => -d.value}
                filterKey="Lokasjon"
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Ankomster etter skipskategori "
                dimension={dimShipCategory}
                group={arrivalsByShipCategory}
                useFlex
                height={1}
                filterKey="Skipskategori"
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Ankomster etter skipstype (inntil 10 største)"
                dimension={dimShipType}
                group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByShipType, 10)}
                useFlex
                height={1}
                filterKey="Skipstype"
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BoxPlot
                chartTitle="Boksplott over antall timer i havn for hver lokalitet i utvalget"
                useFlex
                height={1.5}
                dimension={dimLocation2}
                group={timeInPortByLocationBox}
                minY={-10}
                showOutliers
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <DataTable
                chartTitle="Tid i havn for ankomster etter skip, eta, etd og lokasjon"
                dimension={dimId}
                sortBy={(d) => d.eta}
                width={4}
                size={Infinity}
                useFlex
                columns={[
                  {
                    label: "MMSI",
                    format: (d) => d.mmsi,
                  },
                  {
                    label: "IMO",
                    format: (d) => d.imo,
                  },
                  {
                    label: "Skipsnavn",
                    format: (d) => d.shipname,
                  },
                  {
                    label: "Skipskategori",
                    format: (d) => d.shipcategory,
                  },
                  {
                    label: "Skipstype",
                    format: (d) => d.shiptype,
                  },
                  {
                    label: "ETA",
                    format: (d) => TimeUtils.toCompactDate(d.eta),
                    value: (d) => d.eta,
                  },
                  {
                    label: "ETD",
                    format: (d) => TimeUtils.toCompactDate(d.etd),
                    value: (d) => d.etd,
                  },
                  {
                    label: "Tid i havn",
                    format: (d) => d.hours,
                  },
                  {
                    label: "Lokasjon",
                    format: (d) => d.name,
                  },
                  {
                    label: "Type lokasjon",
                    format: (d) => d.type,
                  },
                ]}
              />
            </AVICol>
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdTimeInPortOSPS = withRouter(DbrdTimeInPortOSPSBase);

export default DbrdTimeInPortOSPS;
