export const MarginsMixin = function (chart, props) {
  const defaultMargins = {
    top: 10,
    right: 50,
    bottom: 30,
    left: 30
  };

  const { margins = [10, 50, 30, 30] } = props;

  if (Array.isArray(margins)) {
    var marginObj = {
      top: margins[0],
      right: margins[1],
      bottom: margins[2],
      left: margins[3]
    };
    chart.margins(marginObj);
  } else if (typeof margins === 'object') {
    var marginObj2 = Object.assign({}, defaultMargins);
    var marginObj3 = Object.assign(marginObj2, margins);
    chart.margins(marginObj3);
  }

  return chart;
};
