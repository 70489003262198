/* eslint-disable react/prop-types */

import "./DbrdShipVoyages.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import { AVIColorScales } from "../../chart-components/AVIColorScales";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { SeriesChart } from "../../chart-components/Charts/SeriesChart";
import { TrackMapChart } from "../../chart-components/ChartsExt/TrackMapChart";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterShipName } from "../../filters/FilterShipName";
import { SimpleTimespan } from "../../filters/SimpleDateTimeControl/SimpleTimespan";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { KyvLayerSwitcher } from "../../ui-components/filter-view/FilterMap/kyv-layer-switcher/KyvLayerSwitcher";

class DbrdShipVoyagesBase extends Component<any, any> {
  static dashboardRoute = "seilas-for-skip";

  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å vise data for flere år om gangen.",
      controls: (
        <div key="fltContainer">
          <FilterShipName
            key="fltShipIds"
            firstYear={2013}
            filter={filter}
            setFilter={setFilter}
          />
          <SimpleTimespan
            key="fltToFromDate"
            filter={filter}
            setFilter={setFilter}
          />
          {/* <FilterToFromDate2
            key="fltToFromDate"
            setFilter={setFilter}
            format={"dd.MM.yyyy"}
            maxTime={TimeUtils.getDateDiff(-3)}
          /> */}
        </div>
      ),
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: undefined,
    };
  }

  static dashboardValidation(filter) {
    return (
      filter.fromTime &&
      filter.toTime &&
      Array.isArray(filter.shipIds) &&
      filter.shipIds.length > 0
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      geoJsonData: null,
      locationInfo: null,
    };
    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    try {
      const { fromTime, toTime, shipIds } = this.props.location.state;

      DataLoader.postApi(
        "/api/voyage/for-ships/by-shipid",
        {
          ShipIds: shipIds.map((d) => d.value),
          StartTime: fromTime,
          EndTime: toTime,
        },
        this.reportProgress
      )
        .then((jsonResponse) => {
          if (!jsonResponse.success) {
            throw new Error("Error loading data for ship ids");
          }

          if (Array.isArray(jsonResponse.data)) {
            jsonResponse.data.forEach((d) => {
              d.eta = new Date(d.eta);
              d.etastr = TimeUtils.toCompactTimestring(d.eta);
              d.etd = new Date(d.etd);
              d.etdstr = TimeUtils.toCompactTimestring(d.etd);
            });
          }
          var chartData = crossfilter(jsonResponse.data);

          var dimVoyages = chartData.dimension((d: any) => [
            d.mmsi,
            d.shipname || "Ukjent",
            d.etd,
            d.eta,
            d.origin || "n/a",
            d.destination || "n/a",
            d.hashazmat || "",
            d.cargogrossquantity || "n/a",
            d.cargonetquantity || "n/a",
          ]);

          var dimDestination = chartData.dimension((d: any) => d.destination);
          var voyagesByDestination = dimDestination.group().reduceCount();

          var dimOrigin = chartData.dimension((d: any) => d.origin);
          var voyagesByOrigin = dimOrigin.group().reduceCount();

          var dimLeg = chartData.dimension(
            (d: any) => `${d.origin}-${d.destination}`
          );
          var voyagesByLeg = dimLeg.group().reduceCount();

          var grpVoyages = dimVoyages.group().reduceCount();

          this.setState({
            chartData,
            grpVoyages,
            dimDestination,
            voyagesByDestination,
            dimOrigin,
            voyagesByOrigin,
            dimLeg,
            voyagesByLeg,
          });

          // Start
          var nsrShipIds = shipIds.map((d) => d.value);
          function datediff(first, second) {
            return Math.round((second - first) / (1000 * 60 * 60 * 24));
          }

          var d0 = new Date(fromTime);
          var d1 = new Date(toTime);
          var d = datediff(d0, d1);
          var dates = [d0];
          var dpr;
          var dcr = new Date(fromTime);
          for (var t = 0; t < d; t++) {
            dcr.setDate(dcr.getDate() + 1);
            if (dpr && dcr.getMonth() > dpr.getMonth()) {
              dates.push(new Date(dcr));
            }
            dpr = new Date(dcr);
          }
          dates.push(d1);

          var promises: any[] = [];

          for (var i = 1; i < dates.length; i++) {
            var min = dates[i - 1];
            var max = dates[i];
            promises.push(
              DataLoader.postApi("/api/tracks/for-ships/by-shipid", {
                ShipIds: nsrShipIds,
                StartTime: min,
                EndTime: max,
              })
            );
          }

          Promise.all(promises).then((trackResponses) => {
            if (
              trackResponses.filter((track) => track.success !== true)
                .length === 0
            ) {
              var tracks = trackResponses
                .reduce((pv, cv) => {
                  return pv.concat(cv.data);
                }, [])
                .map((track) => {
                  var sd = new Date(track.starttime);
                  track.year = sd.getUTCFullYear();
                  track.weekday = sd.getUTCDay() + 1;
                  track.month = sd.getUTCMonth() + 1;
                  track.hour = sd.getUTCHours();
                  return track;
                });

              var trackData = crossfilter(tracks);

              var dimTracks = trackData.dimension((d: any) => d.id);

              var dimYear = trackData.dimension((d: any) => d.year);
              var tracksByYear = dimYear.group().reduceSum((d: any) => d.nmi);

              var dimVesselDate = trackData.dimension((d: any) => [
                d.mmsi,
                new Date(TimeUtils.dateToUTC(d.starttime).toDateString()),
              ]);
              var nmiByVesselDate = dimVesselDate
                .group()
                .reduceSum((d: any) => d.nmi);

              var dimVessel = trackData.dimension((d: any) => [
                d.year,
                d.mmsi,
                d.imo,
                d.callsign,
                d.shipname,
                d.shiptypenor2,
              ]);

              var nmiByVessel = dimVessel.group().reduceSum((d: any) => d.nmi);

              var dimMonth = trackData.dimension((d: any) => d.month);
              var tracksByMonth = dimMonth.group().reduceSum((d: any) => d.nmi);

              var dimWeekday = trackData.dimension((d: any) => d.weekday);
              var tracksByWeekday = dimWeekday
                .group()
                .reduceSum((d: any) => d.nmi);

              var dimHour = trackData.dimension((d: any) => d.hour);
              var tracksByHour = dimHour.group().reduceSum((d: any) => d.nmi);

              this.setState({
                dimHour,
                dimMonth,
                dimTracks,
                dimVessel,
                dimVesselDate,
                dimWeekday,
                dimYear,
                nmiByVessel,
                nmiByVesselDate,
                trackData,
                tracksByHour,
                tracksByMonth,
                tracksByWeekday,
                tracksByYear,
              });
            } else {
              throw new Error("Error no data returned");
            }
          });
        })
        .catch((error) => {
          console.warn(error);
          this.setState({
            chartData: crossfilter([]),
          });
        });
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  loadTrack() {
    this.setState({
      update: true,
    });
  }

  render() {
    const {
      chartData,
      dimDestination,
      dimHour,
      dimLeg,
      dimMonth,
      dimOrigin,
      dimTracks,
      dimVesselDate,
      dimWeekday,
      dimYear,
      grpVoyages,
      nmiByVessel,
      nmiByVesselDate,
      progressData,
      trackData,
      tracksByHour,
      tracksByMonth,
      tracksByWeekday,
      tracksByYear,
      voyagesByDestination,
      voyagesByLeg,
      voyagesByOrigin,
    } = this.state;

    if (!chartData || !trackData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    return (
      <div className="AppView">
        <AVIDashboard
          title="Seilas for valgte skip"
          desc={
            "Viser data fra AIS og registerpliktige seilas fra Safe Sea Net for valgte skip og tidsperiode. Ikke alle skip er registerpliktige og ikke alle skip har AIS skrudd på til alle tider."
          }
          spacing={10}
          useFlex
          cfilter={chartData}
          filter={this.props.location.state}
        >
          {/* AIS data */}
          {trackData.size() > 0 && (
            <React.Fragment>
              <AVIRow>
                <TrackMapChart
                  chartTitle="Seilaslinjer, viser inntil 10000"
                  dimension={dimTracks}
                  chartData={trackData}
                  intersectGeom={[]}
                  colorScale={AVIColorScales.shipCategory}
                  categoryProperty="sgroup2"
                  maxFeatures={10000}
                  height={2}
                  width={1}
                  useFlex
                >
                  <KyvLayerSwitcher top="10px" right="10px" />
                </TrackMapChart>
              </AVIRow>
              <AVIRow>
                <SeriesChart
                  chartTitle="Utseilt distanse etter dag"
                  dimension={dimVesselDate}
                  group={nmiByVesselDate}
                  // width={4}
                  height={2}
                  brushOn
                  yAxisLabel="Utseilt distance (nm)"
                  xAxisLabel="Dato"
                  filterPrefix="Tidsrom"
                  rotateXAxisLabels={-45}
                  xAxisPadding={1}
                  x={d3.scaleTime()}
                  title={(d) =>
                    TimeUtils.formatDateInYear(d.key[1]) +
                    "." +
                    d.key[0] +
                    ": " +
                    d.value
                  }
                  useFlex
                />
              </AVIRow>
              <AVIRow>
                <BarChartOrdinal
                  chartTitle="Utseilt distanse per måned"
                  dimension={dimMonth}
                  group={tracksByMonth}
                  ordering={(d) => d.key}
                  yAxisLabel="nm"
                  xAxisLabel="Måned i året (Januar = 1, Desember = 12)"
                  width={1}
                  height={1.5}
                  margins={{ left: 45, bottom: 35 }}
                  filterPrefix="Måned"
                  useFlex
                />
              </AVIRow>
              <AVIRow>
                <BarChartOrdinal
                  chartTitle="Utseilt distanse per år"
                  dimension={dimYear}
                  group={tracksByYear}
                  ordering={(d) => d.key}
                  yAxisLabel="nm"
                  xAxisLabel="Årstall"
                  width={1}
                  height={1.5}
                  margins={{ left: 45, bottom: 35 }}
                  filterPrefix="Ukedag"
                  useFlex
                />
                <BarChartOrdinal
                  chartTitle="Utseilt distanse per måned"
                  dimension={dimMonth}
                  group={tracksByMonth}
                  ordering={(d) => d.key}
                  yAxisLabel="nm"
                  xAxisLabel="Måned i året (Januar = 1, Desember = 12)"
                  width={1}
                  height={1.5}
                  margins={{ left: 45, bottom: 35 }}
                  filterPrefix="Måned"
                  useFlex
                />
                <BarChartOrdinal
                  chartTitle="Utseilt distanse per ukedag"
                  dimension={dimWeekday}
                  group={tracksByWeekday}
                  ordering={(d) => d.key}
                  yAxisLabel="nm"
                  xAxisLabel="Dag i uken (Mandage = 1, Søndag = 7)"
                  width={1}
                  height={1.5}
                  margins={{ left: 45, bottom: 35 }}
                  filterPrefix="Ukedag"
                  useFlex
                />
              </AVIRow>
              <AVIRow>
                <BarChartOrdinal
                  chartTitle="Utseilt distanse per time i døgnet"
                  dimension={dimHour}
                  group={tracksByHour}
                  ordering={(d) => d.key}
                  yAxisLabel="nm"
                  xAxisLabel="Time i døgnet"
                  width={4}
                  height={1.5}
                  margins={{ left: 45, bottom: 35 }}
                  filterPrefix="Time i døgnet"
                  useFlex
                />
              </AVIRow>
              <AVIRow>
                <DataTable
                  chartTitle="Tabell over utseilt distanse"
                  dimension={GroupUtils.RemoveEmptyBins(nmiByVessel)}
                  sortBy={(d) => d.key[0] + "-" + NumUtils.pad(d.key[1], 2)}
                  width={4}
                  size={Infinity}
                  order={d3.ascending}
                  showSortControls
                  useFlex
                  //d.year, d.month, d.mmsi, d.imo, d.callsign, d.shipname, d.shiptypenor2]);

                  columns={[
                    {
                      label: "År",
                      title: "År",
                      format: (d) => d.key[0],
                    },
                    {
                      label: "MMSI",
                      title: "MMSI nummer",
                      format: (d) => d.key[1],
                    },
                    {
                      label: "Skipsnavn",
                      title: "Skip",
                      format: (d) => d.key[4],
                    },
                    {
                      label: "NM",
                      title: "Utseilt distanse i nautiske mil (NM)",
                      format: (d) => NumUtils.decimal2(d.value),
                    },
                  ]}
                />
              </AVIRow>
            </React.Fragment>
          )}

          {/* NSR data */}
          {chartData.size() > 0 && (
            <React.Fragment>
              <AVIRow>
                <h2>Data for valgte skip fra NSR</h2>
              </AVIRow>
              <AVIRow>
                <RowChart
                  chartTitle="Strekninger (10 mest seilte)"
                  group={GroupUtils.RemoveEmptyBinsTopN(voyagesByLeg, 10)}
                  dimension={dimLeg}
                  ordering={(d) => -d.value}
                  width={1.33}
                  height={1.5}
                  yAxisLabel="Antall seilas"
                  filterPrefix="Strekning"
                  useFlex
                />
                <RowChart
                  chartTitle="Avgangslokasjoner (10 med flest avganger)"
                  group={GroupUtils.RemoveEmptyBinsTopN(voyagesByOrigin, 10)}
                  dimension={dimOrigin}
                  ordering={(d) => -d.value}
                  width={1.34}
                  height={1.5}
                  yAxisLabel="Antall seilas"
                  filterPrefix="Avgangslokasjon(er)"
                  useFlex
                />
                <RowChart
                  chartTitle="Ankomstlokasjoner (10 med flest ankomster)"
                  group={GroupUtils.RemoveEmptyBinsTopN(
                    voyagesByDestination,
                    10
                  )}
                  dimension={dimDestination}
                  ordering={(d) => -d.value}
                  width={1.33}
                  height={1.5}
                  yAxisLabel="Antall seilas"
                  filterPrefix="Ankomstlokasjon(er)"
                  useFlex
                />
              </AVIRow>
              <AVIRow>
                <DataTable
                  chartTitle="Liste over strekning og skip (kun registerpliktige seilas)"
                  dimension={GroupUtils.RemoveEmptyBins(grpVoyages)}
                  sortBy={(d) => d.key[0] + "-" + NumUtils.pad(d.key[1], 2)}
                  width={4}
                  size={Infinity}
                  order={d3.ascending}
                  showSortControls
                  useFlex
                  columns={[
                    {
                      label: "MMSI",
                      title: "MMSI nummer",
                      format: (d) => d.key[0],
                    },
                    {
                      label: "Navn",
                      title: "Skip",
                      format: (d) => d.key[1],
                    },
                    {
                      label: "ETD",
                      title: "Estimert avgangstidspunkt",
                      format: (d) => TimeUtils.toCompactTimestring(d.key[2]),
                      value: (d) => new Date(d.key[2]),
                    },
                    {
                      label: "ETA",
                      title: "Estimert ankomsttidspunkt",
                      format: (d) => TimeUtils.toCompactTimestring(d.key[3]),
                      value: (d) => new Date(d.key[3]),
                    },
                    {
                      label: "Fra",
                      title: "Avgangslokasjon",
                      format: (d) => d.key[4],
                    },
                    {
                      label: "Til",
                      title: "Ankomstlokasjon",
                      format: (d) => d.key[5],
                    },
                    {
                      label: "Hazmat",
                      title: "Farlig gods",
                      format: (d) => d.key[6],
                    },
                    {
                      label: "Bruttolast",
                      title: "Last (brutto)",
                      format: (d) => d.key[7],
                    },
                    {
                      label: "Nettolast",
                      title: "Last (netto)",
                      format: (d) => d.key[8],
                    },
                  ]}
                />
              </AVIRow>
            </React.Fragment>
          )}
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdShipVoyages = withRouter(DbrdShipVoyagesBase);
