import "../../filter-components/DropDown/Dropdown.scss";

import React, { useCallback } from "react";

import AsyncSelect from "react-select/async";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { WebServiceResponse } from "../../types/WebServiceResponse";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";

export type AISShipregProps = {
  name: string;
  filter: DashboardFilter;
  setFilter: SetDashboardFilterFunc;
  isMulti: boolean;
  label?: string;
};

export function AISShipreg({
  filter,
  setFilter,
  name,
  isMulti = false,
  label = "Velg et eller flere skip",
}: AISShipregProps) {
  const parseOptions = useCallback((response: WebServiceResponse) => {
    return [
      {
        label: "MMSI/IMO - Skipsnavn",
        options: response.data.map((match) => {
          return {
            value: match.mmsi,
            label: match.ship_name,
          };
        }),
      },
    ];
  }, []);

  const queryCombinedShip = useCallback(
    (queryTerm: string, setOptions: (options: any) => void) => {
      if (!filter.fromTime || !filter.toTime) {
        setOptions([]);
        return;
      }
      if (queryTerm && queryTerm.trim().length > 3) {
        DataLoader.getApi("/api/ship/data/ais/free-text", {
          q: queryTerm,
          y1: filter.fromTime.substring(0, 4),
          y2: +filter.toTime.substring(0, 4) + 1,
        }).then((response: any) => {
          if (response.success === true) {
            setOptions(parseOptions(response));
          }
        });
      }
    },
    [filter.fromTime, filter.toTime, parseOptions]
  );

  const handleChange = useCallback((opts: any) => {
    setFilter({
      [name]: opts.map((opt: any)=>opt.value).join(","),
    });
  }, [name, setFilter]);

  return (
    <div className="filter-control">
      <label>{label}</label>
      <AsyncSelect
        cacheOptions
        placeholder={"MMSI, IMO eller skipsnavn"}
        loadingMessage={(d) => "Søker"}
        loadOptions={queryCombinedShip}
        defaultOptions
        isMulti={isMulti}
        onChange={handleChange}
      />
    </div>
  );
}

export default AISShipreg;
