import React, { Component } from "react";
import * as d3 from "d3";
import * as dc from "dc";
import { BarChart } from "./BarChart";

export class BarChartOrdinal extends Component {

  
  render() {
    
    return (
      <BarChart
        x={d3.scaleBand()}
        xUnits={dc.units.ordinal}
        barPadding={0.1}
        outerPadding={0.05}
        {...this.props}
      />
    );
  }
}
