import "./DbrdCruiseDetailed.scss";

import * as d3 from "d3";
import reductio from "reductio";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { PieChart } from "../../chart-components/Charts/PieChart";
import { PivotTable } from "../../chart-components/ChartsExt/PivotTable";
import { PointDimMapChart } from "../../chart-components/ChartsExt/PointDimMapChart";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { KYVGroupings } from "../../utils/KYVGroupings";

class DbrdCruiseDetailedBase extends Component<any, any> {
  static dashboardRoute = "cruise-detailed";

  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å vise data for flere år om gangen, man trenger ikke angi filtre på nåværende tidspunkt.",
      controls: undefined,
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: undefined,
    };
  }

  static dashboardValidation(filter) {
    return true;
  }

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      geoJsonData: null,
      locationInfo: null,
    };
    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    try {
      var mergeRequests = [
        DataLoader.postApi(
          "/api/voyage/cruise/detailed",
          {},
          this.reportProgress
        ),
      ];
      Promise.all(mergeRequests)
        .then((jsonResponses) => {
          // Check that all responses are successful
          if (jsonResponses.some((r) => r.success === false)) {
            throw new Error("Error loading cruise data");
          }

          var voyages = jsonResponses[0].data;

          this.setState({
            chartData: crossfilter(voyages),
          });
        })
        .catch((error) => {
          console.warn(error);
          this.setState({
            chartData: crossfilter([]),
          });
        });
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, progressData } = this.state;

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    var dimYear = chartData.dimension((d) => {
      return d.year;
    });
    var arrivalsByYear = dimYear.group().reduceSum((d) => d.num_arrivals);
    var passengersByYear = dimYear.group().reduceSum((d) => d.sum_passengers);

    var dimYearAndCounty = chartData.dimension((d) => [
      d.dcounty ? d.dcounty : "Ukjent",
      d.year,
    ]);
    var arrivalsByYearAndCounty = dimYearAndCounty
      .group()
      .reduceSum((d) => d.num_arrivals);

    var dimMonth = chartData.dimension((d) => d.month);
    var arrivalsByMonth = dimMonth.group().reduceSum((d) => d.num_arrivals);

    var dimLengthGroup = chartData.dimension((d) =>
      d.lgrp ? d.lgrp : "Ukjent"
    );
    var arrivalsByLengthGroup = dimLengthGroup
      .group()
      .reduceSum((d) => d.num_arrivals);

    var dimGtonnageGroup = chartData.dimension((d) =>
      d.gtgrp ? d.gtgrp : "Ukjent"
    );
    var arrivalsByGtonnageGroup = dimGtonnageGroup
      .group()
      .reduceSum((d) => d.num_arrivals);

    var dimCounty = chartData.dimension((d) =>
      d.dcounty ? d.dcounty : "Ukjent"
    );
    var arrivalsByCounty = dimCounty.group().reduceSum((d) => d.num_arrivals);

    var dimCouncil = chartData.dimension((d) =>
      d.dcouncil ? d.dcouncil : "Ukjent"
    );
    var arrivalsByCouncil = dimCouncil.group().reduceSum((d) => d.num_arrivals);

    var dimPoint = chartData.dimension((d) => [d.dlon, d.dlat, d.destination]);
    var arrivalsByPoint = dimPoint.group().reduceSum((d) => d.num_arrivals);

    var dimDestination = chartData.dimension((d) =>
      d.destination ? d.destination : "Ukjent"
    );
    var arrivalsByDestination = dimDestination
      .group()
      .reduceSum((d) => d.num_arrivals);
    var passengersByDestination = dimDestination
      .group()
      .reduceSum((d) => d.sum_passengers);

    var dimShipId = chartData.dimension((d) => [
      d.year,
      d.month,
      d.origin || "Ukjent",
      d.destination || "Ukjent",
      d.mmsi || "",
      d.shipid || "",
      d.shipname || "Ukjent",
      d.lgrp || "Ukjent",
      d.gtgrp || "Ukjent",
      d.day,
      d.imo || "",
      d.callsign || "",
      d.length || 0,
      d.gt || 0,
      d.flag || "Ukjent",
    ]);
    var arrivalsByShipId = dimShipId.group().reduceSum((d) => d.sum_passengers);

    var dimDestinationTime = chartData.dimension((d) => [
      d.year,
      d.month,
      d.destination || "Ukjent",
    ]);

    var pbdtReducer = (reductio() as any).count(true);
    pbdtReducer.value("arrivals").sum((d) => d.num_arrivals);
    pbdtReducer.value("passengers").sum((d) => d.sum_passengers);

    var passengersByDestinationTime = dimDestinationTime.group();
    pbdtReducer(passengersByDestinationTime);

    return (
      <div className="AppView">
        <AVIDashboard
          title="Havneinformasjon: Cruiseankomster (detaljert)"
          desc={
            "Individuelle cruiseankomster til lokasjoner i Norge basert på innrapporterte seilas i SafeSeaNet, inkludert antall passasjerer ombord i skipene"
          }
          spacing={10}
          keyName="mmsino"
          type="count"
          // valueAccessor={(d) => d.count}
          group={dimYear.groupAll()}
          useFlex
        >
          <AVIRow>
            <PointDimMapChart
              chartTitle="Havner etter antall ankomster (viser sum av alle år i utvalget)"
              dimension={dimPoint}
              group={arrivalsByPoint}
              width={4}
              height={2}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <PivotTable
              chartTitle="Ankomster per år etter fylke"
              height={2}
              width={4}
              group={arrivalsByYearAndCounty}
              dimension={dimCounty}
              rowLabel="Fylke"
              colLabel="År"
              rowKeyIndex={0}
              colKeyIndex={1}
              filterPrefix="Fylke"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={arrivalsByYear}
              dimension={dimYear}
              chartTitle="Ankomster etter år"
              width={2}
              height={1}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d) => d.key}
              yAxisLabel="Antall ankomster"
              xAxisLabel="År"
              filterPrefix="År"
              rotateXAxisLabels={-15}
              useFlex
            />
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByMonth, 25)}
              dimension={dimMonth}
              chartTitle="Ankomster etter måned"
              width={2}
              height={1}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d) => d.key}
              xAxisLabel="Månad"
              filterPrefix="Månad"
              rotateXAxisLabels={-15}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={passengersByYear}
              dimension={dimYear}
              chartTitle="Antall passasjerer etter år"
              width={4}
              height={1}
              margins={{ left: 60, bottom: 50 }}
              ordering={(d) => d.key}
              xAxisLabel="År"
              filterPrefix="År"
              rotateXAxisLabels={-15}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByCounty, 30)}
              dimension={dimCounty}
              chartTitle="Ankomster etter fylke"
              width={4}
              height={1.5}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d) => -d.value}
              xAxisLabel="Fylke"
              filterPrefix="Fylke"
              rotateXAxisLabels={-15}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByCouncil, 25)}
              dimension={dimCouncil}
              chartTitle="Ankomster etter kommune (25 største)"
              width={4}
              height={1.5}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d) => -d.value}
              xAxisLabel="Kommune"
              filterPrefix="Kommune"
              rotateXAxisLabels={-25}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              useFlex
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByDestination, 10)}
              dimension={dimDestination}
              chartTitle="Ankomster etter destinasjon (10 største)"
              width={2}
              height={1.5}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d) => -d.value}
              xAxisLabel="Ankomststed"
              filterPrefix="Ankomststed"
              rotateXAxisLabels={-25}
            />
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(
                passengersByDestination,
                10
              )}
              useFlex
              dimension={dimDestination}
              chartTitle="Passasjerer etter destinasjon (10 største)"
              width={2}
              height={1.5}
              margins={{ left: 60, bottom: 50 }}
              ordering={(d) => -d.value}
              xAxisLabel="Ankomststed"
              filterPrefix="Ankomststed"
              rotateXAxisLabels={-25}
            />
          </AVIRow>
          <AVIRow>
            <PieChart
              useFlex
              chartTitle="Ankomster per lengdegruppe (LOA)"
              width={2}
              height={1.5}
              margins={{ top: 20, bottom: 50 }}
              innerRadius={50}
              dimension={dimLengthGroup}
              group={arrivalsByLengthGroup}
              minAngleForLabel={0.15}
              filterPrefix="Lengdegruppe"
            />
            <BarChartOrdinal
              group={arrivalsByGtonnageGroup}
              dimension={dimGtonnageGroup}
              chartTitle="Ankomster per bruttotonnasjegruppe (BT)"
              width={2}
              height={1.5}
              margins={{ left: 50, bottom: 65 }}
              ordering={(d) => {
                return KYVGroupings.grossTonnageGroups.indexOf(d.key);
              }}
              xAxisLabel="Ankomster"
              useFlex
              filterPrefix="Tonnasjegruppe"
              rotateXAxisLabels={-35}
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over ankomster per måned, strekning og skip"
              dimension={GroupUtils.RemoveEmptyBins(arrivalsByShipId)}
              sortBy={(d) => d.key[0] + "-" + NumUtils.pad(d.key[1], 2)}
              width={4}
              size={Infinity}
              order={d3.ascending}
              showSortControls
              useFlex
              columns={[
                {
                  label: "År-måned",
                  title: "Ankomstår-måned",
                  format: (d) =>
                    `${d.key[0]}-${NumUtils.pad(d.key[1], 2)}-${NumUtils.pad(
                      d.key[9],
                      2
                    )}`,
                },
                {
                  label: "Fra",
                  title: "Avgangssted",
                  format: (d) => d.key[2],
                },
                {
                  label: "Til",
                  title: "Ankomststed",
                  format: (d) => d.key[3],
                },
                {
                  label: "MMSI",
                  title: "MMSI nummer",
                  format: (d) => d.key[4],
                },
                {
                  label: "IMO",
                  title: "IMO nummer",
                  format: (d) => d.key[10],
                },
                {
                  label: "Kallesignal",
                  title: "Kallesignal",
                  format: (d) => d.key[11],
                },
                {
                  label: "Skip",
                  title: "Skipsnavn",
                  format: (d) => d.key[6],
                },
                {
                  label: "Flagg",
                  title: "Flaggstat",
                  format: (d) => d.key[14],
                },
                {
                  label: "Lengde",
                  title: "Lengde",
                  format: (d) => NumUtils.decimal2(d.key[12]),
                },
                {
                  label: "Lengdegruppe",
                  title: "Lengdegruppe",
                  format: (d) => d.key[7],
                },
                {
                  label: "Bt",
                  title: "Bruttotonnasje",
                  format: (d) => NumUtils.decimal2(d.key[13]),
                },
                {
                  label: "Tonnasjegruppe",
                  title: "Tonnasjegruppe",
                  format: (d) => d.key[8],
                },
                {
                  label: "Passasjerer",
                  title: "Antall passasjerer",
                  format: (d) => d.value,
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdCruiseDetailed = withRouter(DbrdCruiseDetailedBase);

export default DbrdCruiseDetailed;
