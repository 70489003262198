import "./DashboardGrid.scss";

import PropTypes from "prop-types";
import React from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardLink,
  CardText,
  Container,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { readFiltered } from "../../../redux/digiThemeReducer";
import { bindActionCreators as scopedBindActionCreators } from "../../../utils/scopedReducer";

const mapDispatchToProps = (dispatch) => {
  return {
    loadDashboards: scopedBindActionCreators(
      readFiltered,
      dispatch,
      "dashboards"
    ),
  };
};

const mapStateToProps = (state) => ({
  dashboards: state.dashboards.records,
  sessionToken: state.auth.sessionToken,
});

class DashboardGridBase extends React.Component {
  static propTypes = {
    loadDashboards: PropTypes.func.isRequired,
    dashboards: PropTypes.array.isRequired,
    sessionToken: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  };

  componentDidMount() {
    this.loadDashboards();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sessionToken !== this.props.sessionToken) {
      this.loadDashboards();
    }
  }

  loadDashboards() {
    this.props.loadDashboards(
      window.kyvDashboardConfig.dashboardsDt.columns,
      {
        sortColumns: [
          {
            name: "title",
          },
        ],
      },
      "3857",
      0,
      0,
      null
    );
  }

  render() {
    const { dashboards } = this.props;

    if (!Array.isArray(dashboards) || dashboards.length < 1) return null;

    return (
      <Container className="mb-5">
        <div className="mb-4">
          <h3>Data og tjenester</h3>
        </div>
        <div className="d-flex flex-wrap gap-3">
          {dashboards.map((d) => (
            <Card
              key={`dashboard-${d.id}`}
              style={{ flex: 1, minWidth: "250px", maxWidth: "350px" }}
            >
              {d.media && (
                <CardImg
                  alt="Dashboard article"
                  src={`${window.kyvDashboardConfig.adaptiveUrl}WebServices/generic/Media.asmx/Download?uuid=${d.media}&thumbnail_size=small"/}`}
                />
              )}
              <CardBody>
                <Card.Title>{d.title}</Card.Title>
                <CardText
                  dangerouslySetInnerHTML={{
                    __html: d.content.slice(0, 200),
                  }}
                />
                <div className="d-flex">
                  <CardLink
                    as={Link}
                    className="ms-auto"
                    to={`tallogstatistikk/${d.uri}/filter`}
                  >
                    Vis
                  </CardLink>
                </div>
              </CardBody>
            </Card>
          ))}
        </div>
      </Container>
    );
  }
}

export const DashboardGrid = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardGridBase);

export default DashboardGrid;
