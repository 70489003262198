import './LocationTypeAhead.scss';

import React, { Component } from 'react';

import AsyncSelect from 'react-select/async';
import { DataLoader } from '../../ui-components/DataLoader/DataLoader';
import { ExecUtils } from '../../chart-components/ChartUtils/ExecUtils';
import PropTypes from 'prop-types';

export class LocationTypeAhead extends Component {
  static propTypes = {
    setValue: PropTypes.func.isRequired,
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    defaultValue: PropTypes.array
  };

  static defaultProps = {
    selectedValue: undefined,
    isMulti: false,
    label: 'Velg et skip',
    defaulValue: undefined
  };

  constructor (props) {
    super(props);
    this.loadLocations = this.loadLocations.bind(this);
    this.parseOptions = this.parseOptions.bind(this);
  }

  parseOptions (response) {
    var i = 0;
    return [
      {
        label: 'Anløpssted',
        options: response.data.map(match => {
          return {
            key: match.id + '-' + i++,
            value: match.id,
            label: match.name + ', ' + match.country + ', (' + match.code + ')',
            data: {
            }
          };
        })
      }
    ];
  }

  loadLocations (queryTerm, setOptions) {
    if (queryTerm && queryTerm.trim().length > 3) {
      DataLoader.postApi('/api/location/free-text', {
        FreeText: queryTerm
      }).then(response => {
        if (response.success === true) {
          setOptions(this.parseOptions(response));
        }
      });
    }
  }

  render () {
    const { label, isMulti, setValue, defaultValue } = this.props;

    return (
      <div className='filter-control'>
        <label>{label}</label>
        <AsyncSelect
          cacheOptions
          placeholder={'Navn på anløpssted'}
          loadingMessage={d => 'Søker'}
          loadOptions={ExecUtils.debounceTwoArgs(this, this.loadLocations, 500)}
          defaultOptions
          defaultValue={defaultValue}
          isMulti={isMulti}
          onChange={setValue}
        />
      </div>
    );
  }
}
