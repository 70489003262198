import "./DbrdPassline.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { AVIColorScales } from "../../chart-components/AVIColorScales";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartLinear } from "../../chart-components/Charts/BarChartLinear";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { TrackMapChart } from "../../chart-components/ChartsExt/TrackMapChart";
import GroupedNumberDisplayMax from "../../chart-components/GroupedNumberDisplayMax";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { CrossfilterRecord } from "../../custom";
import { FilterToFromMonth } from "../../filters/FilterToFromMonth";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { SelectPasslinesInMap } from "../../ui-components/filter-view/FilterMap/SelectPasslinesInMap";
import { KyvLayerSwitcher } from "../../ui-components/filter-view/FilterMap/kyv-layer-switcher/KyvLayerSwitcher";
import { KYVGroupings } from "../../utils/KYVGroupings";
import { isArrayN } from "../../utils/array/isArrayN";

export type DbrdPasslineProps = any;
export type DbrdPasslineState = any;

class DbrdPasslineBase extends Component<DbrdPasslineProps, DbrdPasslineState> {
  static dashboardRoute = "passeringslinjer";

  static dashboardFilters(filter, setFilter, passlines, renderSelection) {
    return {
      helpMessage:
        "Passeringslinjer genererer mye data. For områder med mye trafikk kan du velge inntil 3-4 måneder om gangen, for områder med mindre trafikk kan du ofte velge ut et år eller mer. Prøv deg frem. Gå tilbake og begrens utvalget hvis fremdriftsindikatoren blir stående å spinne på skjermen lenge. Tidspunkt i dataene er angitt som UTC.",
      controls: [
        <FilterToFromMonth
          firstYear={2013}
          key="flt-to-from-month"
          filter={filter}
          setFilter={setFilter}
        />,
        <SelectPasslinesInMap
          key="flt-sel-pass-map"
          name="geom"
          filter={filter}
          setFilter={setFilter}
        />,
      ],
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  static dashboardValidation(filter) {
    if (filter.fromTime && filter.toTime && isArrayN(filter.geom, 1, true)) {
      return true;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  defaultParams = {
    geom: "MULTILINESTRING((8.33168 58.14161,8.45436 58.07631))",
    fromYear: 2019,
    toYear: 2019,
    fromMonth: 5,
    toMonth: 6,
  };

  dims: any[] = [];

  constructor(props) {
    super(props);
    this.dims = [];
    this.state = {
      chartData: null,
      geom: null,
    };

    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    try {
      var paramsToUse = this.props.location.state;
      if (paramsToUse && paramsToUse["geom"] === undefined) {
        paramsToUse = this.defaultParams;
      }

      const { geom, fromTime, toTime } = paramsToUse;

      if (isArrayN(geom, 1, true) && fromTime && toTime) {
        DataLoader.postApi(
          "/api/tracks/intersects-line",
          {
            Geom: geom[0],
            StartTime: fromTime,
            EndTime: toTime,
          },
          this.reportProgress
        )
          .then((jsonResponse) => {
            this.setState({
              chartData: crossfilter(jsonResponse.data),
              geom: geom,
            });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: crossfilter([]),
            });
          });
      } else {
        throw new Error("Missing dashboard selection parameters");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  componentWillUnmount() {
    this.dims.forEach((dim) => {
      if (dim && "dispose" in dim) {
        dim.dispose();
      }
    });
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, geom, progressData } = this.state;

    const { fromYear, fromMonth, toYear, toMonth, passlineName } =
      this.props.location.state;

    var dashboardTitle = passlineName
      ? "Passeringslinje: " + passlineName
      : "Brukerdefinert passeringslinje";

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    var dimTracks = chartData.dimension(function (d: CrossfilterRecord) {
      return [d3.timeDay(new Date(d.starttime)), d.id];
    }, true);

    var dimCount = chartData.dimension(
      (d: CrossfilterRecord) => d.mmsi + "/" + d.callsign + "/" + d.shipname
    );

    var dimDraft = chartData.dimension((d: CrossfilterRecord) =>
      Math.round(d.draught)
    );
    var shipsByDraft = dimDraft.groupAll();
    var sailingsByDraft = dimDraft
      .group()
      .reduceSum((d: CrossfilterRecord) => d.crossings);

    var dimLength = chartData.dimension((d: CrossfilterRecord) =>
      d.length ? d.length : -1
    );
    var maxLength = dimLength.groupAll();

    var dimLengthGroup = chartData.dimension((d: CrossfilterRecord) =>
      KYVGroupings.getShipLengthGroup(d.length)
    );
    var sailingsByLength = dimLengthGroup
      .group()
      .reduceSum((d: CrossfilterRecord) => d.crossings);
    var shipLengthLabels = KYVGroupings.getShipLengthLabels();

    var dimBreadth = chartData.dimension((d: CrossfilterRecord) =>
      d.breadth ? d.breadth : -1
    );
    var maxBreadth = dimBreadth.groupAll();

    var dimHeight = chartData.dimension((d: CrossfilterRecord) =>
      d.height !== 0 ? d.height : -1
    );
    var maxHeight = dimHeight.groupAll();

    var dimShipCategory = chartData.dimension((d: CrossfilterRecord) =>
      d.sgroup2 ? d.sgroup2.trim() : "Ukjent"
    );
    var voyagesPerShipCategory = dimShipCategory
      .group()
      .reduceSum((d: CrossfilterRecord) => d.crossings);

    var dimShipType = chartData.dimension((d: CrossfilterRecord) =>
      d.shiptypenor2 ? d.shiptypenor2.trim() : "Ukjent"
    );
    var voyagesPerShipType = dimShipType
      .group()
      .reduceSum((d: CrossfilterRecord) => d.crossings);

    var dimWeekday = chartData.dimension((d: CrossfilterRecord) => {
      let jsdn = new Date(d.endtime).getDay();
      return jsdn > 0 ? jsdn + 1 : 1;
    });
    var voyagesPerWeekday = dimWeekday
      .group()
      .reduceSum((d: CrossfilterRecord) => d.crossings);

    var dimMonth = chartData.dimension(
      (d: CrossfilterRecord) => new Date(d.endtime).getMonth() + 1
    );
    var voyagesPerMonth = dimMonth
      .group()
      .reduceSum((d: CrossfilterRecord) => d.crossings);

    var dimHourOfDay = chartData.dimension((d: CrossfilterRecord) =>
      new Date(d.endtime).getHours()
    );
    var voyagesPerHour = dimHourOfDay
      .group()
      .reduceSum((d: CrossfilterRecord) => d.crossings);

    var dimCallSign = chartData.dimension((d: CrossfilterRecord) => d.callsign);

    this.dims.push(
      dimBreadth,
      dimCallSign,
      dimCount,
      dimDraft,
      dimHeight,
      dimHourOfDay,
      dimLength,
      dimLengthGroup,
      dimMonth,
      dimShipCategory,
      dimShipType,
      dimTracks
    );

    return (
      <div className="AppView">
        <AVIDashboard
          title={dashboardTitle}
          desc={`Antall passeringer fra ${fromYear}-${fromMonth} til og med ${toYear}-${toMonth}. Alle tidspunkt er angitt i UTC.`}
          cfilter={chartData}
          keyName="crossings"
          units="Passeringer"
          filter={this.props.location.state}
          type="sum"
          group={dimCount.groupAll()}
          useFlex
        >
          <AVIRow>
            <GroupedNumberDisplayMax
              chartTitle="Største dimensjoner"
              height={0.5}
              shipsByDraft={shipsByDraft}
              maxLength={maxLength}
              maxHeight={maxHeight}
              maxBreadth={maxBreadth}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <AVICol>
              <TrackMapChart
                chartTitle="Passeringer (viser inntil 5 000)"
                dimension={dimTracks}
                intersectGeom={geom}
                chartData={chartData}
                colorScale={AVIColorScales.shipCategory}
                categoryProperty="sgroup2"
                maxFeatures={5000}
                height={3}
                width={1}
                useFlex
                allowFullscreen
              >
                <KyvLayerSwitcher top="10px" right="10px" />
              </TrackMapChart>
            </AVICol>
            <AVICol>
              <BarChartOrdinal
                chartTitle="Passeringer etter lengdegruppe"
                dimension={dimLengthGroup}
                group={sailingsByLength}
                ordering={(d) => shipLengthLabels.indexOf(d.key)}
                width={2}
                height={1.5}
                filterPrefix="Lengdegruppe"
                useFlex
              />
              <BarChartOrdinal
                chartTitle="Passeringer etter draft"
                dimension={dimDraft}
                group={sailingsByDraft}
                width={2}
                height={1.5}
                filterPrefix="Draft"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Passeringer etter skipskategori"
              width={2}
              height={1.5}
              colors={(d) => {
                return AVIColorScales.shipCategory(d);
              }}
              dimension={dimShipCategory}
              group={voyagesPerShipCategory}
              filterPrefix="Skipskategori"
              useFlex
            />
            <RowChart
              chartTitle="Passeringer etter skipstype (10 største)"
              description="Filtrer på skipskategori for å se de 10 største skipstypene innen kategorien"
              width={2}
              height={1.5}
              dimension={dimShipType}
              group={GroupUtils.RemoveEmptyBinsTopN(voyagesPerShipType, 10)}
              filterPrefix="Skipstype"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartLinear
              chartTitle="Passeringer etter time i døgnet"
              dimension={dimHourOfDay}
              group={voyagesPerHour}
              width={4}
              height={1}
              centerBar={false}
              filterPrefix="Time i døgnet"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              chartTitle="Passeringer etter måned"
              dimension={dimMonth}
              group={voyagesPerMonth}
              width={2}
              height={1.5}
              filterPrefix="Draft"
              xAxisLabel="Måned 1"
              useFlex
            />
            <BarChartOrdinal
              chartTitle="Passeringer etter ukedag"
              dimension={dimWeekday}
              group={voyagesPerWeekday}
              width={2}
              height={1.5}
              filterPrefix="Ukedag"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over skip med registrerte passeringer"
              dimension={dimCallSign}
              sortBy={(d: CrossfilterRecord) =>
                d.mmsi +
                "-" +
                TimeUtils.toTimestamp(d.starttime) +
                "-" +
                TimeUtils.toTimestamp(d.endtime)
              }
              useFlex
              width={4}
              size={Infinity}
              columns={[
                {
                  label: "MMSI#",
                  format: (d: CrossfilterRecord) => d.mmsi || 0,
                },
                {
                  label: "IMO#",
                  format: (d: CrossfilterRecord) => d.imo || 0,
                },
                {
                  label: "Kallesignal",
                  format: (d: CrossfilterRecord) => d.callsign || "",
                },
                {
                  label: "Skipsnavn",
                  format: (d: CrossfilterRecord) => d.shipname || "",
                },
                {
                  label: "Passeringer",
                  format: (d: CrossfilterRecord) => d.crossings || "",
                },
                {
                  label: "Tidspunkt (UTC)",
                  format: (d: CrossfilterRecord) =>
                    TimeUtils.toCompactTimestring(new Date(d.endtime)),
                },
                {
                  label: "Skipstype",
                  format: (d: CrossfilterRecord) => d.shiptypenor2 || "",
                },
                {
                  label: "BT",
                  title: "Bruttotonnasje",
                  format: (d: CrossfilterRecord) =>
                    NumUtils.integer(d.grosstonnage),
                  value: (d: CrossfilterRecord) => d.grosstonnage,
                },
                {
                  label: "DWT",
                  title: "Dødvektstonnasje",
                  format: (d: CrossfilterRecord) => NumUtils.integer(d.dwt),
                  value: (d: CrossfilterRecord) => d.dwt,
                },
                {
                  label: "L",
                  title: "LOA (Length Over All)",
                  format: (d: CrossfilterRecord) =>
                    NumUtils.decimal2(0 + d.length),
                  value: (d: CrossfilterRecord) => +d.length,
                },
                {
                  label: "B",
                  title: "Skipsbredde",
                  format: (d: CrossfilterRecord) =>
                    NumUtils.decimal2(d.breadth),
                  value: (d: CrossfilterRecord) => d.breadth,
                },
                {
                  label: "H",
                  title: "Skipshøyde (air draught)",
                  format: (d: CrossfilterRecord) => NumUtils.decimal2(d.height),
                  value: (d: CrossfilterRecord) => d.height,
                },
                {
                  label: "D",
                  title: "Dyptgående",
                  format: (d: CrossfilterRecord) =>
                    NumUtils.decimal2(d.draught),
                  value: (d: CrossfilterRecord) => d.draught,
                },
                {
                  label: "Land",
                  format: (d: CrossfilterRecord) => d.countrynamenor || "",
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdPassline = withRouter(DbrdPasslineBase);

export default DbrdPassline;
