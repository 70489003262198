import * as d3 from "d3";
import React, { useCallback, useMemo } from "react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { EventLike } from "../../types/Shims";
import { SimpleDate } from "./SimpleDate";

const currentDate: Date = new Date();
currentDate.setHours(0, 0, 0, 0);

const currentMonth: Date = new Date(currentDate);
currentMonth.setDate(1);

const currentYear: Date = new Date(currentMonth);
currentYear.setMonth(0);

export const defaultDate = currentDate;

export type SimpleTimespanProps = {
  filter: DashboardFilter;
  setFilter: SetDashboardFilterFunc;
  fromLabel?: string;
  fromProperty?: string;
  toLabel?: string;
  toProperty?: string;
  maxDays?: number;
  minAge?: number;
  format?: "d.M.y" | "d.M.y HH:mm";
  utc?: boolean;
};

export function SimpleTimespan({
  setFilter,
  filter,
  fromProperty = "fromTime",
  fromLabel = "Fra dato",
  toProperty = "toTime",
  toLabel = "Til dato",
  format = "d.M.y",
  utc = false,
  maxDays = undefined,
  minAge = 0,
}: SimpleTimespanProps) {
  
  const toDate: (d: string) => Date = useCallback((value: string) => {
    if (!value) throw new Error("No value supplied");
    if (value.endsWith("Z")) {
      value = value.replace("Z", "");
    }
    const d = d3.isoParse(value);
    if (d === null) {
      throw new Error(`Could not parse value ${value} as ISO-date`);
    }
    return d;
  }, []);

  const toString: (d: Date) => string = useMemo(() => {
    if (!utc) {
      return d3.timeFormat("%Y-%m-%dT%H:%M:00");
    } else {
      return d3.timeFormat("%Y-%m-%dT%H:%M:00.000Z");
    }
  }, [utc]);

  const maxHigherDate: string = useMemo(() => {
    if (!maxDays || !filter[fromProperty]) return "";
    const d = toDate(filter[fromProperty]);
    d.setDate(d.getDate() + maxDays);
    return toString(d);
  }, [maxDays, filter, fromProperty, toDate, toString]);

  const maxLowerDate: string = useMemo(() => {
    if (minAge === undefined) return "";
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() - minAge);
    return toString(d);
  }, [minAge, toString]);

  const handleChange = useCallback(
    (evt: EventLike<string>) => {
      let d1: string = filter[fromProperty] ?? "";
      let d2: string = filter[toProperty] ?? "";
      if (evt.target.name === fromProperty) {
        d1 = evt.target.value;
      }
      if (evt.target.name === toProperty) {
        d2 = evt.target.value;
      }
      if (!d1 && d2) {
        d2 = "";
      } else if (!d2 && d1) {
        d2 = d1;
      } else if (d1 && d2 && d1 > d2) {
        d2 = d1;
      }
      setFilter({
        [fromProperty]: d1,
        [toProperty]: d2,
      });
    },
    [filter, fromProperty, toProperty, setFilter]
  );

  const setSpan = useCallback(
    (from: Date, to: Date) => {
      setFilter({
        [fromProperty]: toString(from),
        [toProperty]: toString(to),
      });
    },
    [fromProperty, setFilter, toProperty, toString]
  );

  const setPredefinedRange = useCallback(
    (range: string) => {
      switch (range) {
        case "currentWeek":
          const startOfWeek = new Date(currentDate);
          startOfWeek.setDate(startOfWeek.getDate() - 7);
          setSpan(startOfWeek, currentDate);
          break;
        case "currentMonth":
          const currentMonthStart = new Date(currentDate);
          currentMonthStart.setDate(1);
          setSpan(currentMonthStart, currentDate);
          break;
        case "lastMonth":
          const lastMonthEnd = new Date(currentDate);
          lastMonthEnd.setDate(1);
          const lastMonthStart = new Date(lastMonthEnd);
          lastMonthStart.setMonth(lastMonthStart.getMonth() - 1);
          setSpan(lastMonthStart, lastMonthEnd);
          break;
        case "currentYear":
          const currentYearStart = new Date(currentDate);
          currentYearStart.setMonth(0, 1);
          const currentYearEnd = new Date(currentDate);
          currentYearEnd.setDate(1);
          setSpan(currentYearStart, currentYearEnd);
          break;
        case "lastYear":
          const lastYearEnd = new Date(currentDate);
          lastYearEnd.setDate(1);
          const lastYearStart = new Date(lastYearEnd);
          lastYearStart.setFullYear(lastYearStart.getFullYear() - 1);
          setSpan(lastYearStart, lastYearEnd);
          break;
        case "lastThreeYears":
          const lastThreeYearsEnd = new Date(currentDate);
          lastThreeYearsEnd.setDate(1);
          const lastThreeYearsStart = new Date(lastThreeYearsEnd);
          lastThreeYearsStart.setFullYear(
            lastThreeYearsStart.getFullYear() - 3
          );
          setSpan(lastThreeYearsStart, lastThreeYearsEnd);
          break;
        default:
          break;
      }
    },
    [setSpan]
  );

  return (
    <>
      <SimpleDate
        name={fromProperty}
        label={fromLabel}
        value={filter[fromProperty]}
        onChange={handleChange}
        displayFormat={format}
        // max={maxLowerDate}
        utc={utc}
      />
      <SimpleDate
        name={toProperty}
        label={toLabel}
        min={filter[fromProperty]}
        value={filter[toProperty]}
        onChange={handleChange}
        displayFormat={format}
        max={maxHigherDate > maxLowerDate ? maxLowerDate : maxHigherDate}
        utc={utc}
      />
      <FormGroup>
        <FormLabel>Forhåndsdefinerte perioder</FormLabel>
        <FormControl
          as="select"
          onChange={(evt: EventLike<string>) =>
            setPredefinedRange(evt.target.value)
          }
        >
          <option value="">Velg en periode</option>
          <option value="currentWeek">Siste 7 dager</option>
          <option value="currentMonth">Så langt denne måneden</option>
          <option value="lastMonth">Forrige måned</option>
          <option value="currentYear">Så langt i år</option>
          <option value="lastYear">Forrige år</option>
          <option value="lastThreeYears">Siste tre år</option>
        </FormControl>
      </FormGroup>
    </>
  );
}
