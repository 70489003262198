import "./AVIRow.scss";
import React, { PropsWithChildren } from "react";

export type AVIRowProps = PropsWithChildren & {
  style?: any;
  height?: number;
};

export function AVIRow({
  children,
  height = undefined,
  style = {},
}: AVIRowProps) {
  if (height) {
    style.height = `${height * 20}vh`;
    style.minHeight = "20vh";
  }

  return (
    <div className="avi-row my-3" style={style}>
      {children}
    </div>
  );
}
