import BaseLayer from "ol/layer/Base";
import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownHeader,
  DropdownMenu,
  DropdownToggle,
} from "react-bootstrap";
import { DropDirection } from "react-bootstrap/esm/DropdownContext";
import { AlignType } from "react-bootstrap/esm/types";
import { useMap } from "../../../../context/MapProvider";
import { KyvLayerSwitcherItemLayer } from "./KyvLayerSwitcherItemLayer";

export type KyvLayerSwitcherProps = {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  align?: AlignType;
  drop?: DropDirection;
};

export function KyvLayerSwitcher({
  top = "unset",
  left = "unset",
  bottom = "unset",
  right = "unset",
  drop = "down",
  align = "end",
}: KyvLayerSwitcherProps) {
  const { map } = useMap();
  const [layers, setLayers] = useState<BaseLayer[]>([]);

  useEffect(() => {
    if (!map) return;

    const onChange = (evt: any) => {
      setLayers(map.getLayers().getArray().slice(0));
    };

    map.getLayers().on(["add", "remove"], onChange);

    onChange(null);

    return () => {
      map.getLayers().un(["add", "remove"], onChange);
    };
  }, [map]);

  return (
    <div
      style={{ position: "absolute", top, left, right, bottom }}
    >
      <Dropdown align={align} drop={drop}>
        <DropdownToggle
          title="Layers"
          variant="light"
          className="border-dark no-caret"
        >
          <i className="bi bi-layers"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownHeader>Kartinnhold</DropdownHeader>
          {layers.length > 0 &&
            layers
              .filter((lyr: BaseLayer) => lyr.get("title"))
              .map((lyr: BaseLayer, lyrIdx: number) => {
                return (
                  <KyvLayerSwitcherItemLayer
                    key={`layer-switcher--item-${lyr.get("title")}-${lyrIdx}`}
                    layer={lyr}
                  />
                );
              })}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
