import './ShipTypeAhead.scss';

import React, { Component } from 'react';

import AsyncSelect from 'react-select/async';
import { DataLoader } from '../../ui-components/DataLoader/DataLoader';
import { ExecUtils } from '../../chart-components/ChartUtils/ExecUtils';
import PropTypes from 'prop-types';

export class ShipTypeAhead extends Component {
  static propTypes = {
    setValue: PropTypes.func.isRequired,
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    defaultValue: PropTypes.array
  };

  static defaultProps = {
    selectedValue: undefined,
    isMulti: false,
    label: 'Velg et skip',
    defaulValue: undefined
  };

  constructor (props) {
    super(props);
    this.loadShips = this.loadShips.bind(this);
    this.parseOptions = this.parseOptions.bind(this);
  }

  parseOptions (response) {
    var i = 0;
    return [
      {
        label: 'Skipsnavn (MMSI/IMO)',
        options: response.data.map(match => {
          return {
            key: 'ship' + i++,
            value: match.shipid,
            label: match.shipname + ' (' + match.mmsi + '/' + match.imo + ')',
            data: {
              mmsi: match.mmsi,
              imo: match.imo,
              callsign: match.callsign
            }
          };
        })
      }
    ];
  }

  loadShips (queryTerm, setOptions) {
    if (queryTerm && queryTerm.trim().length > 3) {
      DataLoader.postApi('/api/ship/free-text', {
        FreeText: queryTerm
      }).then(response => {
        if (response.success === true) {
          setOptions(this.parseOptions(response));
        }
      });
    }
  }

  render () {
    const { label, isMulti, setValue, defaultValue } = this.props;

    return (
      <div className='filter-control'>
        {label && (<label>{label}</label>)}
        <AsyncSelect
          cacheOptions
          placeholder={'Skipsnavn, MMSI# eller IMO#'}
          loadingMessage={d => 'Søker'}
          loadOptions={ExecUtils.debounceTwoArgs(this, this.loadShips, 500)}
          defaultOptions
          defaultValue={defaultValue}
          isMulti={isMulti}
          onChange={setValue}
        />
      </div>
    );
  }
}
