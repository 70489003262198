import "rc-slider/assets/index.css";
import "./DbrdFollow.scss";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { PosUtils } from "../../chart-components/ChartUtils/PosUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { CBoxMenu } from "../../chart-components/Charts/CBoxMenu";
import { ReplayMapChart } from "../../chart-components/ChartsExt/ReplayMapChart";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import TimeLine from "../../chart-components/TimeLine/TimeLine";
import AISShipreg from "../../filters/AISShipregTypeahead/AISShipreg";
import { SimpleTimespan } from "../../filters/SimpleDateTimeControl/SimpleTimespan";
import withRouter from "../../hocs/withRouter";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { KyvLayerSwitcher } from "../../ui-components/filter-view/FilterMap/kyv-layer-switcher/KyvLayerSwitcher";
import { AisPosMsg } from "../../chart-components/ChartUtils/AisMsg";

type DbrdFollowProps = any;

type DbrdFollowState = any;

class DbrdFollowBase extends Component<DbrdFollowProps, DbrdFollowState> {
  static dashboardRoute = "sporskip";

  static Filter = () => {
    return <>A filter</>;
  };

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Sporing av seilas for utvalgte skip genererer mye data. Det er ment benyttet for korte tidsrom for å analysere hendelsesforløp. Om man velger et enkeltskip kan man velge inntil en uke, om man velger flere skip inntil en dag eller mer. Prøv deg frem. Gå tilbake og begrens utvalget hvis fremdriftsindikatoren blir stående å spinne på skjermen lenge. Tidspunkt i dataene er angitt som UTC.",
      controls: [
        <SimpleTimespan
          key="flt-timespan"
          filter={filter}
          setFilter={setFilter}
          format="d.M.y HH:mm"
          minAge={4}
          maxDays={31}
          utc
        />,
        <AISShipreg
          key="flt-tahead"
          name="mmsi"
          isMulti
          filter={filter}
          setFilter={setFilter}
        />,
      ],
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: undefined,
    };
  }

  static dashboardValidation(filter) {
    if (filter.fromTime && filter.toTime && filter.mmsi) {
      return true;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  startTime: Date;
  endTime: Date;
  currentTime: Date;
  sliderStepSize = 2;
  timeout: any = null;
  playbackSpeed = 1;
  animationRange: number;
  currentAnimationFrame: number;
  constructor(props) {
    super(props);

    const { fromTime, toTime } = this.props.location.state;

    // Initialize properties

    this.startTime = TimeUtils.isoParse(fromTime)!;
    this.endTime = TimeUtils.isoParse(toTime)!;
    this.animationRange =
      (this.endTime.getTime() - this.startTime.getTime()) / 1000;
    this.currentTime = new Date(this.startTime);
    this.currentAnimationFrame = 0;

    // Set initial state
    this.state = {
      timeSeries: null,
      start: this.startTime,
      end: this.endTime,
      span: (this.endTime.getTime() - this.startTime.getTime()) / 1000,
    };

    // Bind functions to current scope
    this.reportProgress = this.reportProgress.bind(this);
    this.setGeoJson = this.setGeoJson.bind(this);
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidMount() {
    try {
      const { mmsi, fromTime, toTime } = this.props.location.state;
      var mmsiIds = mmsi
        .toString()
        .split(",")
        .map((d: string) => {
          return parseInt(d, 10);
        });

      var startTime = TimeUtils.isoParse(fromTime);
      var endTime = TimeUtils.isoParse(toTime);

      if (mmsiIds.length >= 0 && startTime && endTime) {
        var httpReqs = [
          DataLoader.post(
            process.env.REACT_APP_DASHBOARD_WS_API +
              "/api/ais/positions/for-mmsis-time",
            {
              MmsiIds: mmsiIds,
              Start: TimeUtils.formatUtcTimestampYmdHM(startTime),
              End: TimeUtils.formatUtcTimestampYmdHM(endTime),
            },
            this.reportProgress
          ),
          DataLoader.post(
            process.env.REACT_APP_DASHBOARD_WS_API +
              "/api/ais_shipreg/statinfo/for-mmsis-time",
            {
              MmsiIds: mmsiIds,
              Start: startTime.getFullYear(),
              End: endTime.getFullYear(),
            },
            this.reportProgress
          ),
        ];

        Promise.all(httpReqs)
          .then((responses) => {
            var posArr: AisPosMsg<string>[] = responses[0].data;
            var statInfoArr = responses[1].data;
            var bbox = PosUtils.getBbox(posArr, 2, 3);
            var timeSeriesCollection = PosUtils.createTimeSeries(
              posArr,
              statInfoArr
            );

            if (timeSeriesCollection.length > 0) {
              var initialPositions = timeSeriesCollection.map((d) => {
                return d.positions[0][1];
              });
              if (initialPositions.length > 0) {
                this.currentTime = initialPositions.sort(TimeUtils.dateSort)[0];
                this.currentAnimationFrame =
                  (this.currentTime.getTime() - this.startTime.getTime()) /
                  1000;
              }
            }

            this.setState({
              timeSeries: timeSeriesCollection,
              chartData: crossfilter(timeSeriesCollection),
              mmsiIds: mmsiIds,
              bbox: bbox,
            });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: crossfilter([]),
            });
          });
      } else {
        throw new Error("No selection parameters specified");
      }
    } catch (error) {
      console.warn("Error occurred");
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  setGeoJson(geoJsonData) {
    this.setState({
      geoJson: geoJsonData.positions,
      trackGeoJson: geoJsonData.tracks,
    });
  }

  render() {
    const { chartData, geoJson, trackGeoJson, bbox, mmsiIds, progressData } =
      this.state;
    const { fromTime, toTime } = this.props.location.state;

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    var dimCount = chartData.dimension((d) => {
      return d.mmsi;
    });

    var grpSegmentsPerShip = dimCount.group().reduceCount();

    return (
      <div className="AppView">
        <AVIDashboard
          title="Følg valgte skip innen tidsrom"
          desc={
            "Vis skipsbevegelser i tidsrommet fra " +
            fromTime +
            " til " +
            toTime +
            " for skip med mmsi: " +
            mmsiIds.join(", ") +
            ". Alle tidspunkt er angitt i UTC"
          }
          group={dimCount.groupAll()}
          units="seilaslinjer"
          cfilter={chartData}
          filter={this.props.location.state}
          spacing={20}
          useFlex
        >
          <AVIRow height={5}>
            <AVICol width={3}>
              <ReplayMapChart
                chartTitle="Kartvisning"
                bbox={bbox}
                geoJson={geoJson}
                trackGeoJson={trackGeoJson}
                height={4}
                labelProperty="mmsi"
                colorProperty="mmsi"
                allowFullscreen
                useFlex
              >
                <KyvLayerSwitcher top="10px" right="10px" />
              </ReplayMapChart>
              <TimeLine
                fromTime={fromTime}
                toTime={toTime}
                chartData={chartData}
                setGeoJson={this.setGeoJson}
                startTime={TimeUtils.isoFormat(this.currentTime)}
                utc
              />
            </AVICol>
            {chartData.size() > 1 && (
              <AVICol>
                <CBoxMenu
                  chartTitle="Avgrens til skip/mmsi nummer"
                  height={0.5}
                  dimension={dimCount}
                  group={grpSegmentsPerShip}
                  multiple
                  filterPrefix="Skip"
                  useFlex
                />
              </AVICol>
            )}
          </AVIRow>
          <AVIRow style={{ height: "75vh" }}></AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdFollow = withRouter(DbrdFollowBase);

export default DbrdFollow;
