import "./DbrdToFromCountries.scss";

import * as d3 from "d3";
import * as dc from "dc";
import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartGroupedNew } from "../../chart-components/Charts/BarChartGroupedNew";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { SelectMenu } from "../../chart-components/Charts/SelectMenu";
import { TypeaheadMenu } from "../../chart-components/Charts/TypeaheadMenu";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromMonth } from "../../filters/FilterToFromMonth";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { GetCountryFor2CharCode } from "../../utils/codelists";
import { FlightMap } from "./FlightMap";

const noNorwegianLocations = (d) => {
  return (
    d.value !== 0 &&
    ["NORGE", "SVALBARD OG JAN MAYEN", "XZ", "ZZ"].indexOf(d.key[1]) === -1
  );
};

const noForeignLocations = (d) => {
  return (
    d.value !== 0 && ["NORGE", "SVALBARD OG JAN MAYEN"].indexOf(d.key[1]) > -1
  );
};

class DbrdToFromCountriesBase extends Component<any, any> {
  static dashboardRoute = "to-from-countries";

  static dashboardFilters(filter, setFilter, passlines, renderSelection) {
    return {
      helpMessage:
        "Dette dashboardet genererer middels datamengder, prøv med et år eller to om gangen",
      controls: [
        <FilterToFromMonth
          key="flt-tf-month"
          firstYear={2013}
          setFilter={setFilter}
          filter={filter}
        />,
      ],
    };
  }

  static dashboardSettings() {
    return {};
  }

  static dashboardValidation(filter) {
    if (filter.fromTime && filter.toTime) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        fromTime: PropTypes.string.isRequired,
        toTime: PropTypes.string.isRequired,
      }),
    }),
  };

  dims: any[];

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
    };
    this.dims = [];
    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    try {
      const { fromTime, toTime } = this.props.location.state;
      DataLoader.getApi(
        `/api/voyage/international/${fromTime}/${toTime}`,
        {},
        this.reportProgress
      )
        .then((res) => {
          // Check that all responses are successful
          if (!res.success)
            throw new Error("Could not load international voyage data");
          let _data = res.data.map((r) => {
            return {
              ...r,
              arr_ctry: GetCountryFor2CharCode(r.arr_ctry),
              dep_ctry: GetCountryFor2CharCode(r.dep_ctry),
            };
          });
          this.setState({
            chartData: crossfilter(_data),
          });
        })
        .catch((error) => {
          console.warn(error);
          this.setState({
            chartData: crossfilter([]),
          });
        });
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  componentWillUnmount() {
    this.dims.forEach((dim) => {
      if (dim && "dispose" in dim) {
        dim.dispose();
      }
    });
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, progressData } = this.state;
    const { fromTime, toTime } = this.props.location.state;

    var dashboardTitle =
      "Avganger og ankomster fra/til Norge til/fra utenlandske lokasjoner";

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    const dimFromToPoint = chartData.dimension((d) => [
      d.dlon,
      d.dlat,
      d.alon,
      d.alat,
    ]);
    const grpFromToPoint = dimFromToPoint.group().reduceCount();

    const dimFromToCountry = chartData.dimension((d) =>
      [d.dep_ctry, d.arr_ctry].sort().join(" <-> ")
    );
    const grpVoyagesByFromToCountry = dimFromToCountry
      .group()
      .reduceSum((d) => d.num_voyages);

    const dimDepartureCountry = chartData.dimension((d) => d.dep_ctry);
    const grpVoyagesByDepartureCountry = dimDepartureCountry
      .group()
      .reduceSum((d) => d.num_voyages);

    const dimArrivalCountry = chartData.dimension((d) => d.arr_ctry);
    const grpVoyagesByArrivalCountry = dimArrivalCountry
      .group()
      .reduceSum((d) => d.num_voyages);

    const dimArrivalLocation = chartData.dimension((d) => d.arr_loc);

    const dimArrivalsFromAbroad = chartData.dimension((d) => [
      d.dep_loc,
      d.dep_ctry,
    ]);
    const grpIncomingIntVoyages = dimArrivalsFromAbroad
      .group()
      .reduceSum((d) => d.num_voyages);

    const dimDeparturesAbroad = chartData.dimension((d) => [
      d.arr_loc,
      d.arr_ctry,
    ]);
    const grpOutgoingIntVoyages = dimDeparturesAbroad
      .group()
      .reduceSum((d) => d.num_voyages);

    const dimShipCategory = chartData.dimension((d) =>
      d.shipgroup ? d.shipgroup : "Ukjent"
    );
    const grpVoyagesByShipCategory = dimShipCategory
      .group()
      .reduceSum((d) => d.num_voyages);

    const dimShipType = chartData.dimension((d) =>
      d.shiptype ? d.shiptype : "Ukjent"
    );
    const grpVoyagesByShipType = dimShipType
      .group()
      .reduceSum((d) => d.num_voyages);

    const dimMonth = chartData.dimension((d) => d.month);
    const grpVoyagesByMonth = dimMonth.group().reduceSum((d) => d.num_voyages);

    const dimYearMonth = chartData.dimension((d) => [d.year, d.month]);
    const grpVoyagesByYearMonth = dimYearMonth
      .group()
      .reduceSum((d) => d.num_voyages);

    const dimYear = chartData.dimension((d) => d.year);
    const grpVoyagesByYear = dimYear.group().reduceSum((d) => d.num_voyages);

    this.dims.push(
      dimArrivalCountry,
      dimArrivalLocation,
      dimArrivalsFromAbroad,
      dimDepartureCountry.dimDeparturesAbroad,
      dimFromToCountry,
      dimFromToCountry,
      dimFromToPoint,
      dimMonth,
      dimShipCategory,
      dimShipType,
      dimYear,
      dimYearMonth
    );

    return (
      <div className="AppView">
        <AVIDashboard
          title={dashboardTitle}
          desc={`Informasjon om ankomster til norske lokasjoner fra utlandet og avganger fra norske lokasjoner til utlandet i tidsrommet fra ${TimeUtils.toCompactDate(
            new Date(fromTime)
          )} til ${TimeUtils.toCompactDate(
            new Date(toTime)
          )}. Basert på data fra NSR`}
          cfilter={chartData}
          useFlex
        >
          <AVIRow>
            <AVICol>
              <FlightMap dimension={dimFromToPoint} group={grpFromToPoint} />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <SelectMenu
                chartTitle="Velg avgangsland (ett eller flere)"
                dimension={dimDepartureCountry}
                order={(d) => d.value}
                group={grpVoyagesByDepartureCountry}
                numberItems={10}
                multiple
              />
            </AVICol>
            <AVICol>
              <SelectMenu
                chartTitle="Velg ankomstland (ett eller flere)"
                dimension={dimArrivalCountry}
                order={(d) => d.value}
                group={grpVoyagesByArrivalCountry}
                numberItems={10}
                multiple
              />
            </AVICol>
            <AVICol>
              <TypeaheadMenu
                chartTitle="Avgrens til ankomstlokasjon"
                dimension={dimArrivalLocation}
                isMulti
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Land med flest seilas til/fra Norge"
              width={2}
              height={1.5}
              dimension={dimFromToCountry}
              group={GroupUtils.RemoveEmptyBinsTopN(
                grpVoyagesByFromToCountry,
                15
              )}
              filterPrefix="Fra-til land"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <AVICol>
              <RowChart
                chartTitle="Seilas etter skipskategori"
                width={1.5}
                height={1}
                dimension={dimShipCategory}
                group={grpVoyagesByShipCategory}
                filterPrefix="Skipskategori"
                useFlex
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Seilas etter skipstype"
                width={1}
                height={1.5}
                dimension={dimShipType}
                group={GroupUtils.RemoveEmptyBinsTopN(grpVoyagesByShipType, 15)}
                filterPrefix="Skipstype"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Utenlandske lokasjoner med flest avganger"
              width={1}
              height={1.5}
              dimension={dimArrivalsFromAbroad}
              group={GroupUtils.RemoveBinsByFuncTopN(
                grpIncomingIntVoyages,
                15,
                noNorwegianLocations
              )}
              filterPrefix="Avgangsland"
              useFlex
            />
            <RowChart
              chartTitle="Utenlandske lokasjoner med flest ankomster"
              width={1}
              height={1.5}
              dimension={dimDeparturesAbroad}
              group={GroupUtils.RemoveBinsByFuncTopN(
                grpOutgoingIntVoyages,
                15,
                noNorwegianLocations
              )}
              filterPrefix="Avgangsland"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Norske lokasjoner med flest avganger"
              width={1}
              height={1.5}
              dimension={dimArrivalsFromAbroad}
              group={GroupUtils.RemoveBinsByFuncTopN(
                grpIncomingIntVoyages,
                15,
                noForeignLocations
              )}
              filterPrefix="Avgangsland"
              useFlex
            />
            <RowChart
              chartTitle="Norske lokasjoner med flest ankomster"
              width={1}
              height={1.5}
              dimension={dimDeparturesAbroad}
              group={GroupUtils.RemoveBinsByFuncTopN(
                grpOutgoingIntVoyages,
                15,
                noForeignLocations
              )}
              filterPrefix="Avgangsland"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                group={grpVoyagesByMonth}
                dimension={dimMonth}
                chartTitle="Seilas etter måned i året"
                width={1}
                height={1}
                margins={{ left: 50, bottom: 50 }}
                ordering={(d) => d.key}
                yAxisLabel="Antall hendelser"
                xAxisLabel="Måned i året (Jan = 1, Des = 12)"
                filterPrefix="Måned i året"
                rotateXAxisLabels={-15}
                useFlex
              />
            </AVICol>
            <AVICol>
              <BarChartOrdinal
                chartTitle="Seilas etter år"
                group={grpVoyagesByYear}
                dimension={dimYear}
                width={1}
                height={1}
                margins={{ left: 50, bottom: 50 }}
                ordering={(d) => d.key}
                yAxisLabel="Antall seilas"
                xAxisLabel="År"
                filterPrefix="År"
                rotateXAxisLabels={-15}
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <BarChartGroupedNew
              chartTitle="Seilas etter måned og år"
              width={1}
              height={1.5}
              dimension={dimYearMonth}
              group={grpVoyagesByYearMonth}
              filterPrefix="År, måned"
              x={d3.scaleBand()}
              xUnits={dc.units.ordinal}
              renderLabel
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over seilas"
              dimension={dimFromToCountry}
              sortBy={(d) => `${d.year}-${d.month}`}
              width={4}
              size={Infinity}
              order={d3.ascending}
              showSortControls
              columns={[
                {
                  label: "År",
                  title: "Årstall",
                  format: (d) => d.year,
                },
                {
                  label: "Måned",
                  title: "Måned i året",
                  format: (d) => d.month,
                },
                {
                  label: "Avgangsland",
                  title: "Avgangsland",
                  format: (d) => d.dep_ctry,
                },
                {
                  label: "Avgangslokasjon",
                  title: "Avgangslokasjon",
                  format: (d) => d.dep_loc,
                },
                {
                  label: "Ankomstland",
                  title: "Ankomstland",
                  format: (d) => d.arr_ctry,
                },
                {
                  label: "Ankomstlokasjon",
                  title: "Ankomstlokasjon",
                  format: (d) => d.arr_loc,
                },
                {
                  label: "Skipskategori",
                  title: "Skipskategori",
                  format: (d) => d.shipgroup,
                },
                {
                  label: "Skipstype",
                  title: "Skipstype",
                  format: (d) => d.shiptype,
                },
                {
                  label: "Antall seilas",
                  title: "Antall seilas",
                  format: (d) => d.num_voyages,
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdToFromCountries = withRouter(DbrdToFromCountriesBase);

export default DbrdToFromCountries;
