import * as d3 from "d3";
import React, { useCallback, useMemo } from "react";
import { FormGroup, FormLabel, InputGroup } from "react-bootstrap";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import DateTimePicker, { DateTimePickerProps } from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import { EventLike } from "../../types/Shims";
import { createEventLike } from "../../utils/createEventLike";
import "./SimpleDate.scss";

type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;

export type SimpleDateProps = Overwrite<
  DateTimePickerProps,
  {
    name: string;
    label?: string;
    value: string;
    onChange: (evt: EventLike<string>) => void;
    utc?: boolean;
    min?: string;
    max?: string;
    displayFormat?: "d.M.y" | "d.M.y HH:mm";
  }
>;

export function SimpleDate({
  name,
  value,
  label = "Dato",
  onChange,
  utc = false,
  displayFormat = "d.M.y",
  min,
  max,
  ...rdtpProps
}: SimpleDateProps) {
  
  const toDate = useCallback(
    (value: string|undefined) => {
      if (!value) return "";
      if (value.endsWith("Z")) {
        value = value.replace("Z","");
      }
      return d3.isoParse(value);
    },
    []
  );
  
  const toString : (d: Date)=>string = useMemo(() => {
    if (!utc) {
      return d3.timeFormat("%Y-%m-%dT%H:%M:00");
    } else {
      return d3.timeFormat("%Y-%m-%dT%H:%M:00.000Z");
    }
  }, [utc]);

  const [incomingValue, incomingMinDate, incomingMaxDate] : Date[] = useMemo(()=>{
    return [toDate(value), toDate(min), toDate(max)];
  }, [max, min, toDate, value])

  const emitChange = useCallback(
    (d: Date | undefined) => {
      onChange(createEventLike(name, d ? toString(d) : ""));
    },
    [name, onChange, toString]
  );

  const handleChange: (evt: EventLike<Date>) => void = useCallback(
    (evt: EventLike<Date>) => {
      emitChange(evt.target.value);
    },
    [emitChange]
  );

  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <InputGroup className="d-flex">
        <DateTimePicker
          className="rounded-1 bg-white flex-1"
          {...rdtpProps}
          minDate={incomingMinDate}
          maxDate={incomingMaxDate}
          name={name}
          value={incomingValue}
          onChange={(evt) =>
            handleChange(createEventLike<Date>(name, evt as Date))
          }
          format={displayFormat}
          showLeadingZeros
          yearPlaceholder="åååå"
          monthPlaceholder="MM"
          dayPlaceholder="dd"
          hourPlaceholder="tt"
          minutePlaceholder="mm"
        ></DateTimePicker>
        {utc && <InputGroupText>UTC</InputGroupText>}
      </InputGroup>
    </FormGroup>
  );
}
