import "./DbrdCruise.scss";

import * as d3 from "d3";
import reductio from "../../utils/reductio-proxy";

import crossfilter from "crossfilter2";
import React, { Component } from "react";
import { AVIColorScales } from "../../chart-components/AVIColorScales";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { PieChart } from "../../chart-components/Charts/PieChart";
import { PivotTable } from "../../chart-components/ChartsExt/PivotTable";
import { PointDimMapChart } from "../../chart-components/ChartsExt/PointDimMapChart";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { CrossfilterRecord } from "../../custom";
import withRouter from "../../hocs/withRouter";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader, SuperagentProgress } from "../../ui-components/Loader/Loader";
import { KYVGroupings } from "../../utils/KYVGroupings";

class DbrdCruiseBase extends Component<any, any> {
  dims: any[] = [];

  static dashboardRoute = "cruise";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å vise data for flere år om gangen, man trenger ikke angi filtre på nåværende tidspunkt.",
      controls: undefined,
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: undefined,
    };
  }

  static dashboardValidation(filter: DashboardFilter) {
    return true;
  }

  constructor(props: any) {
    super(props);
    this.state = {
      chartData: null,
      geoJsonData: null,
      locationInfo: null,
    };
    this.reportProgress = this.reportProgress.bind(this);
    this.dims = [];
  }

  componentDidMount() {
    try {
      var mergeRequests = [
        DataLoader.postApi(
          "/api/voyage/aggregated/cruise",
          {},
          this.reportProgress
        ),
      ];
      Promise.all(mergeRequests)
        .then((jsonResponses) => {
          if (jsonResponses.some((res) => res.success !== true))
            throw new Error("Error loading cruise data"); // Check that all responses are successful
          var voyages = jsonResponses[0].data;
          this.setState({
            chartData: crossfilter(voyages),
          });
        })
        .catch((error) => {
          console.warn(error);
          this.setState({
            chartData: crossfilter([]),
          });
        });
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  componentWillUnmount() {
    this.dims.forEach((dim: any) => {
      if (dim && "dispose" in dim) {
        dim.dispose();
      }
    });
  }

  reportProgress(progressData: SuperagentProgress) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, progressData } = this.state as any;

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    var dimYear = chartData.dimension((d: CrossfilterRecord) => {
      return d.year;
    });

    var arrivalsByYear = dimYear
      .group()
      .reduceSum((d: CrossfilterRecord) => d.num_arrivals);
    var passengersByYear = dimYear
      .group()
      .reduceSum((d: CrossfilterRecord) => d.sum_passengers);

    var dimYearAndCounty = chartData.dimension((d: CrossfilterRecord) => [
      d.dcounty ? d.dcounty : "Ukjent",
      d.year,
    ]);
    var arrivalsByYearAndCounty = dimYearAndCounty
      .group()
      .reduceSum((d: CrossfilterRecord) => d.num_arrivals);

    var dimMonth = chartData.dimension((d: CrossfilterRecord) => d.month);
    var arrivalsByMonth = dimMonth
      .group()
      .reduceSum((d: CrossfilterRecord) => d.num_arrivals);

    var dimLengthGroup = chartData.dimension((d: CrossfilterRecord) =>
      d.lgrp ? d.lgrp : "Ukjent"
    );
    var arrivalsByLengthGroup = dimLengthGroup
      .group()
      .reduceSum((d: CrossfilterRecord) => d.num_arrivals);

    var dimGtonnageGroup = chartData.dimension((d: CrossfilterRecord) =>
      d.gtgrp ? d.gtgrp : "Ukjent"
    );
    var arrivalsByGtonnageGroup = dimGtonnageGroup
      .group()
      .reduceSum((d: CrossfilterRecord) => d.num_arrivals);

    var dimCounty = chartData.dimension((d: CrossfilterRecord) =>
      d.dcounty ? d.dcounty : "Ukjent"
    );
    var arrivalsByCounty = dimCounty
      .group()
      .reduceSum((d: CrossfilterRecord) => d.num_arrivals);

    var dimCouncil = chartData.dimension((d: CrossfilterRecord) =>
      d.dcouncil ? d.dcouncil : "Ukjent"
    );
    var arrivalsByCouncil = dimCouncil
      .group()
      .reduceSum((d: CrossfilterRecord) => d.num_arrivals);

    var dimPoint = chartData.dimension((d: CrossfilterRecord) => [
      d.dlon,
      d.dlat,
      d.destination,
    ]);
    var arrivalsByPoint = dimPoint
      .group()
      .reduceSum((d: CrossfilterRecord) => d.num_arrivals);

    var dimDestination = chartData.dimension((d: CrossfilterRecord) =>
      d.destination ? d.destination : "Ukjent"
    );
    var arrivalsByDestination = dimDestination
      .group()
      .reduceSum((d: CrossfilterRecord) => d.num_arrivals);
    var passengersByDestination = dimDestination
      .group()
      .reduceSum((d: CrossfilterRecord) => d.sum_passengers);

    var dimShipId = chartData.dimension((d: CrossfilterRecord) => [
      d.year,
      d.month,
      d.origin || "Ukjent",
      d.destination || "Ukjent",
      d.mmsi || "",
      d.shipid || "",
      d.shipname || "Ukjent",
      d.lgrp || "Ukjent",
      d.gtgrp || "Ukjent",
    ]);
    var arrivalsByShipId = dimShipId
      .group()
      .reduceSum((d: CrossfilterRecord) => d.num_arrivals);

    var dimDestinationTime = chartData.dimension((d: CrossfilterRecord) => [
      d.year,
      d.month,
      d.destination || "Ukjent",
    ]);

    const passengersByDestinationTimeReducer = reductio().count(true);

    passengersByDestinationTimeReducer
      .value("arrivals")
      .sum((d: CrossfilterRecord) => d.num_arrivals);
    passengersByDestinationTimeReducer
      .value("passengers")
      .sum((d: CrossfilterRecord) => d.sum_passengers);

    const passengersByDestinationTime = dimDestinationTime.group();
    passengersByDestinationTimeReducer(passengersByDestinationTime);

    this.dims.push(
      dimCouncil,
      dimCounty,
      dimDestination,
      dimDestinationTime,
      dimGtonnageGroup,
      dimLengthGroup,
      dimMonth,
      dimPoint,
      dimShipId,
      dimYear,
      dimCouncil
    );

    return (
      <div className="AppView">
        <AVIDashboard
          title="Havneinformasjon: Cruiseankomster"
          desc={
            "Cruise ankomster til Norge basert på innrapporterte seilas i SafeSeaNet, inkludert antall passasjerer ombord i skipene"
          }
          spacing={10}
          type="count"
          keyName="mmsino"
          group={dimYear.groupAll()}
          useFlex
          cfilter={chartData}
          filter={this.props.location.state}
        >
          <AVIRow>
            <PointDimMapChart
              chartTitle="Havner etter antall ankomster (viser sum av alle år i utvalget)"
              dimension={dimPoint}
              group={arrivalsByPoint}
              width={2}
              height={4}
              useFlex
            />
            <PivotTable
              chartTitle="Ankomster per år etter fylke"
              width={2}
              height={4}
              group={arrivalsByYearAndCounty}
              dimension={dimCounty}
              rowLabel="Fylke"
              colLabel="År"
              rowKeyIndex={0}
              colKeyIndex={1}
              filterPrefix="Fylke"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={arrivalsByYear}
              dimension={dimYear}
              chartTitle="Ankomster etter år"
              width={2}
              height={1}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d: CrossfilterRecord) => d.key}
              yAxisLabel="Antall ankomster"
              xAxisLabel="År"
              filterPrefix="År"
              rotateXAxisLabels={-15}
              colors={AVIColorScales.colorScaleKyv2024Mono}
              useFlex
            />
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByMonth, 25)}
              dimension={dimMonth}
              chartTitle="Ankomster etter måned"
              width={2}
              height={1}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d: CrossfilterRecord) => d.key}
              xAxisLabel="Månad"
              filterPrefix="Månad"
              rotateXAxisLabels={-15}
              colors={AVIColorScales.colorScaleKyv2024Mono}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={passengersByYear}
              dimension={dimYear}
              chartTitle="Antall passasjerer etter år"
              width={4}
              height={1}
              margins={{ left: 60, bottom: 50 }}
              ordering={(d: CrossfilterRecord) => d.key}
              xAxisLabel="År"
              filterPrefix="År"
              rotateXAxisLabels={-15}
              colors={AVIColorScales.colorScaleKyv2024Mono}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByCounty, 30)}
              dimension={dimCounty}
              chartTitle="Ankomster etter fylke"
              width={4}
              height={1.5}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d: CrossfilterRecord) => -d.value}
              xAxisLabel="Fylke"
              filterPrefix="Fylke"
              rotateXAxisLabels={-15}
              colors={AVIColorScales.colorScaleKyv2024Mono}
              useFlex
            />
          </AVIRow>
          '
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByCouncil, 25)}
              dimension={dimCouncil}
              chartTitle="Ankomster etter kommune (25 største)"
              width={4}
              height={1.5}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d: CrossfilterRecord) => -d.value}
              xAxisLabel="Kommune"
              filterPrefix="Kommune"
              rotateXAxisLabels={-25}
              colors={AVIColorScales.colorScaleKyv2024Mono}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByDestination, 10)}
              dimension={dimDestination}
              chartTitle="Ankomster etter destinasjon (10 største)"
              width={2}
              height={1.5}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d: CrossfilterRecord) => -d.value}
              xAxisLabel="Ankomststed"
              filterPrefix="Ankomststed"
              rotateXAxisLabels={-25}
              colors={AVIColorScales.colorScaleKyv2024Mono}
              useFlex
            />
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(
                passengersByDestination,
                10
              )}
              dimension={dimDestination}
              chartTitle="Passasjerer etter destinasjon (10 største)"
              width={2}
              height={1.5}
              margins={{ left: 60, bottom: 50 }}
              ordering={(d: CrossfilterRecord) => -d.value}
              xAxisLabel="Ankomststed"
              filterPrefix="Ankomststed"
              colors={AVIColorScales.colorScaleKyv2024Mono}
              rotateXAxisLabels={-25}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <PieChart
              chartTitle="Ankomster per lengdegruppe (LOA)"
              width={2}
              height={1.5}
              margins={{ top: 20, bottom: 50 }}
              innerRadius={50}
              dimension={dimLengthGroup}
              group={arrivalsByLengthGroup}
              minAngleForLabel={0.15}
              filterPrefix="Lengdegruppe"
              colors={AVIColorScales.colorScaleKyv2024}
              useFlex
            />
            <BarChartOrdinal
              group={arrivalsByGtonnageGroup}
              dimension={dimGtonnageGroup}
              chartTitle="Ankomster per bruttotonnasjegruppe (BT)"
              width={2}
              height={1.5}
              colors={AVIColorScales.colorScaleKyv2024Mono}
              margins={{ left: 50, bottom: 65 }}
              ordering={(d: CrossfilterRecord) => {
                return KYVGroupings.grossTonnageGroups.indexOf(d.key);
              }}
              xAxisLabel="Ankomster"
              filterPrefix="Tonnasjegruppe"
              rotateXAxisLabels={-35}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over passasjerer ombord ved anløp per lokasjon og måned"
              dimension={GroupUtils.RemoveEmptyBins(
                passengersByDestinationTime
              )}
              sortBy={(d: CrossfilterRecord) =>
                d.key[0] + "-" + NumUtils.pad(d.key[1], 2)
              }
              width={4}
              size={Infinity}
              order={d3.ascending}
              showSortControls
              useFlex
              columns={[
                {
                  label: "År-måned",
                  title: "Ankomstår-måned",
                  format: (d: CrossfilterRecord) =>
                    d.key[0] + "-" + NumUtils.pad(d.key[1], 2),
                },
                {
                  label: "Lokasjon",
                  title: "Lokasjon",
                  format: (d: CrossfilterRecord) => d.key[2],
                },
                {
                  label: "Antall anløp",
                  title: "Antall anløp",
                  format: (d: CrossfilterRecord) => d.value.arrivals.sum,
                },
                {
                  label: "Antall passasjerer",
                  title: "Antall passasjerer",
                  format: (d: CrossfilterRecord) => d.value.passengers.sum,
                },
              ]}
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over ankomster per måned, strekning og skip"
              dimension={GroupUtils.RemoveEmptyBins(arrivalsByShipId)}
              sortBy={(d: CrossfilterRecord) =>
                d.key[0] + "-" + NumUtils.pad(d.key[1], 2)
              }
              width={4}
              size={Infinity}
              order={d3.ascending}
              showSortControls
              useFlex
              columns={[
                {
                  label: "År-måned",
                  title: "Ankomstår-måned",
                  format: (d: CrossfilterRecord) =>
                    d.key[0] + "-" + NumUtils.pad(d.key[1], 2),
                },
                {
                  label: "Fra",
                  title: "Avgangssted",
                  format: (d: CrossfilterRecord) => d.key[2],
                },
                {
                  label: "Til",
                  title: "Ankomststed",
                  format: (d: CrossfilterRecord) => d.key[3],
                },
                {
                  label: "MMSI",
                  title: "MMSI nummer",
                  format: (d: CrossfilterRecord) => d.key[4],
                },
                {
                  label: "Skip",
                  title: "Skipsnavn",
                  format: (d: CrossfilterRecord) => d.key[6],
                },
                {
                  label: "Lengdegruppe",
                  title: "Lengdegruppe",
                  format: (d: CrossfilterRecord) => d.key[7],
                },
                {
                  label: "Tonnasjegruppe",
                  title: "Tonnasjegruppe",
                  format: (d: CrossfilterRecord) => d.key[8],
                },
                {
                  label: "Antall anløp",
                  title: "Antall anløp for måned, strekning og skip",
                  format: (d: CrossfilterRecord) => d.value,
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdCruise = withRouter(DbrdCruiseBase);

export default DbrdCruise;
