import "ol-layerswitcher/src/ol-layerswitcher.css";
import "./LayerSwitcherMixin.scss";

import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import TileArcGISRest from "ol/source/TileArcGISRest";
import TileWMS from "ol/source/TileWMS";
import { Map } from "ol";
import { getLocal, setLocal } from "../../utils/localStorageCache";

export const LayerSwitcherMixin = async function (map : Map) {


  let baatGkt = getLocal("baatGkt", 5);
  if (!baatGkt) {
    const baatResponse = await fetch(
      "https://kystinfo.no/WebServices/generic/Baat.ashx"
    );
    const baatJson = await baatResponse.json();
    const baatGkt = baatJson.token;
    setLocal("baatGkt", baatGkt)
  }

  var baseUrl =
    "https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_WGS84/";
  var mapurl = baseUrl + "GeocacheBasis/MapServer/";
  var graymapurl = baseUrl + "GeocacheGraatone/MapServer/";
  var imageurl = baseUrl + "GeocacheBilder/MapServer/";

  var layers = [
    new LayerGroup({
      properties: {
        title: "Bakgrunnskart",
      },
      layers: [
        new TileLayer({
          properties: {
            title: "Globalt grunnkart",            
            type: "base",
          },
          visible: true,
          source: new OSM(),
        }),        
        new TileLayer({
          properties: {
            title: "Bilde",
            type: "base",
          },
          visible: false,
          source: new TileArcGISRest({
            url: imageurl,
            crossOrigin: "Anonymous",
          }),
        }),
        new TileLayer({
          properties: {
            title: "Grunnkart",
            type: "base",
          },
          visible: false,
          source: new TileArcGISRest({
            url: mapurl,
            params: {
              dpi: 200,
            },
            crossOrigin: "Anonymous",
          }),
        }),
        new TileLayer({
          properties: {
            title: "Sjøkart",
            type: "base",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.cache?",
              "https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.cache?",
              "https://gatekeeper3.geonorge.no/BaatGatekeeper/gk/gk.cache?",
            ],
            params: {
              LAYERS: "sjokartraster",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: false,
              GKT: baatGkt,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "geoserver",
            // Countries have transparency, so do not fade tiles:
            transition: 0,
            crossOrigin: "Anonymous",
          }),
        }),
        new TileLayer({
          properties: {
            title: "Gråtone",
            type: "base",
          },
          visible: false,
          source: new TileArcGISRest({
            url: graymapurl,
            crossOrigin: "Anonymous",
          }),
        }),
        new TileLayer({
          properties: {
            title: "AIS tetthetsplott 250m (2017)",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://wms-geo.kystverket.no/density?version=1.3.0&request=GetCapabilities&service=WMS",
            ],
            params: {
              LAYERS: "AIS-density-Europe",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: true,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "geoserver",
            transition: 0,
          }),
        }),
        new TileLayer({
          properties: {
            title: "AIS tetthetsplott 50m (2017)",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://wms-geo.kystverket.no/density?version=1.3.0&request=GetCapabilities&service=WMS",
            ],
            params: {
              LAYERS: "AIS-density-Norway-2016",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: true,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "geoserver",
            transition: 0,
          }),
        }),
      ],
    }),
    new LayerGroup({
      properties: {
        title: "Temadata",
      },
      layers: [
        new TileLayer({
          properties: {
            title: "ISPS Havneanlegg",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://services.kystverket.no/wms.ashx?version=1.3.0&service=WMS",
            ],
            params: {
              LAYERS: "layer_420",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: true,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "geoserver",
            transition: 0,
            crossOrigin: "Anonymous",
          }),
        }),
        new TileLayer({
          properties: {
            title: "Fartsgrenser for næringsfartøy",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://services.kystverket.no/wms.ashx?version=1.3.0&service=WMS",
            ],
            params: {
              LAYERS: "layer_760,layer_761",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: true,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "geoserver",
            transition: 0,
            crossOrigin: "Anonymous",
          }),
        }),
        new TileLayer({
          properties: {
            title: "Fartsgrenser for fritidsfartøy",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://services.kystverket.no/wms.ashx?version=1.3.0&service=WMS",
            ],
            params: {
              LAYERS: "layer_759,layer_755",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: true,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "geoserver",
            transition: 0,
            crossOrigin: "Anonymous",
          }),
        }),
        new TileLayer({
          properties: {
            title: "Akvakulturanlegg",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://gis.fiskeridir.no/server/services/fiskeridirWMS_akva/MapServer/WMSServer?",
            ],
            params: {
              LAYERS: "akvakultur_lokaliteter",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: true,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "mapserver",
            transition: 0,
            crossOrigin: "Anonymous",
          }),
        }),
      ],
    }),
  ];

  map.getLayers().extend(layers);
  
  return map;
};
