import reductio from "reductio";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartHistogram } from "../../chart-components/Charts/BarChartHistogram";
import { BarChartStacked } from "../../chart-components/Charts/BarChartStacked";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { PivotTable } from "../../chart-components/ChartsExt/PivotTable";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { CrossfilterRecord } from "../../custom";
import { SimpleTimespan } from "../../filters/SimpleDateTimeControl/SimpleTimespan";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";

export type DbrdTimeInPortAllLocationsProps = any;
export type DbrdTimeInPortAllLocationsState = any;

class DbrdTimeInPortAllLocationsBase extends Component<
  DbrdTimeInPortAllLocationsProps,
  DbrdTimeInPortAllLocationsState
> {
  /**
   * The route to be used for the dashboard
   */
  static dashboardRoute = "tid-i-havn-alle-lokasjoner";

  /**
   * The help text and filter controls to be used as initial filtering for the dashboard
   * @param {*} filter
   * @param {*} setFilter
   */
  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å analysere flere år samtidig. Velg en eller flere avgangs- eller ankomsthavner og f.eks. tidsrommet 2015-2018",
      controls: (
        <SimpleTimespan
          key="flt2"
          filter={filter}
          minAge={4}
          setFilter={setFilter}
        />
      ),
    };
  }

  /**
   * The dashboard settings, i.e. map interaction filter controls, selectable layers etc.
   */
  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  /**
   * The dashboard validation function
   * @param {*} filter
   */
  static dashboardValidation(filter) {
    if (filter.fromTime && filter.toTime) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  /**
   * TestRoute
   *
   * @param {*} props An object looking like this {a:, b:}
   */
  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      countyMap: {},
      locationData: [],
    };
  }

  componentDidMount() {
    try {
      const { fromTime, toTime } = this.props.location.state;
      if (fromTime && toTime) {
        Promise.all([
          DataLoader.postApi("/api/location/time-in-port/all", {
            StartTime: fromTime,
            EndTime: toTime,
          }),
          DataLoader.getApi("/api/location/counties"),
        ])
          .then((res) => {
            // Convert ETA/ETD date properties from data type string to Date

            var countyMap = {};
            res[1].data.forEach((c) => {
              countyMap[c.countyno] = c.countyname;
            });

            res[0].data.forEach((rec) => {
              rec.eta = new Date(rec.eta);
              rec.etd = new Date(rec.etd) || "";
              rec.vs = 1;
            });

            this.setState({
              chartData: crossfilter(
                res[0].data.filter((d) => {
                  // return true;
                  return ["NO", "SJ"].indexOf(d.country) > -1;
                })
              ),
              countyMap: countyMap,
            });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: crossfilter([]),
            });
          });
      } else {
        throw new Error('Missing dashboard selection parameters')
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  render() {
    const { chartData, countyMap } = this.state;
    const { fromTime, toTime } = this.props.location.state;

    if (!chartData || chartData.size() === 0) {
     return <Loader chartData={chartData} />;
    }

    var hoursReducer = (reductio() as any)
      .count(true)
      .sum(function (d: CrossfilterRecord) {
        return d.hours;
      })
      .avg(true);

    // var avgHoursReducer = reductio().avg(function (d) {
    //   return d.hours;
    // });

    var dimLocation = chartData.dimension((d) => d.name || "");
    var portCallsByLocation = dimLocation.group().reduceCount();

    var avgHoursByLocation = dimLocation.group();
    hoursReducer(avgHoursByLocation);

    var dimShipCategory = chartData.dimension((d) => d.shipcategory || "");
    var arrivalsByShipCategory = dimShipCategory.group().reduceCount();

    var dimShipType = chartData.dimension((d) => d.shiptype || "");
    var arrivalsByShipType = dimShipType.group().reduceCount();

    // var dimWeekDayHour = chartData.dimension((d) => [
    //   TimeUtils.getLocalMonthNameAbb(d.eta),
    //   d.name,
    // ]);

    // var hoursByWeekDayHour = dimWeekDayHour.group();
    // avgHoursReducer(hoursByWeekDayHour);

    // For Pivot table
    var unknownCounty = "Utland/internasjonalt";

    var dimCountyYear = chartData.dimension((d) => [
      d.county ? countyMap[d.county] : unknownCounty,
      d.eta.getFullYear(),
    ]);
    var dimCounty = chartData.dimension((d) =>
      d.county ? countyMap[d.county] : unknownCounty
    );
    var arrivalsByCountyYear = dimCountyYear.group().reduceCount();

    var dimMonth = chartData.dimension((d) => d.eta.getMonth() + 1);

    var dimId = chartData.dimension((d) => d.id || "");

    return (
      <div className="AppView">
        <AVIDashboard
          title="Tid i havn for alle lokasjoner"
          desc={`Statistikk over tid i havn for registerpliktige ankomster til alle lokasjoner i tidsrommet ${TimeUtils.toCompactDate(
            new Date(fromTime)
          )} - ${TimeUtils.toCompactDate(new Date(toTime))}.`}
          spacing={20}
          group={chartData.groupAll()}
          units="opphold i havn"
          useFlex
        >
          <AVIRow>
            <AVICol>
              <PivotTable
                chartTitle="Ankomster per år etter fylke"
                height={2}
                group={arrivalsByCountyYear}
                dimension={dimCounty}
                rowLabel="Fylke"
                colLabel="År"
                rowKeyIndex={0}
                colKeyIndex={1}
                filterPrefix="Fylke"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartHistogram
                chartTitle="Antall ankomster etter tid i havn (timer)"
                chartCrossfilter={chartData}
                height={1.5}
                xAxisLabel={"Timer i havn"}
                yAxisLabel={"Antall ankomster"}
                histogramValueAccessor={(d) => d.hours}
                thresholds={[
                  0, 0.5, 1, 2, 3, 6, 12, 24, 48, 72, 96, 168, 336, 672, 100000,
                ]}
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartStacked
                chartTitle="Ankomster etter skipskategori og måned"
                dimension={dimMonth}
                xAxisTickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                height={1.5}
                gap={2}
                filterPrefix="Ankomstmåned"
                stackKey="shipcategory"
                valueKey="vs"
                renderLabel
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <RowChart
                chartTitle="Gjennomsnittlig antall timer i havn etter lokasjon (inntil 15 største)"
                dimension={dimLocation}
                group={GroupUtils.RemoveEmptyBinsTopN(
                  avgHoursByLocation,
                  15,
                  (a, b) => {
                    if (a.value.avg > b.value.avg) return -1;
                    if (b.value.avg > a.value.avg) return 1;
                    return 0;
                  }
                )}
                height={1.5}
                valueAccessor={(d) => Math.round(d.value.avg * 10) / 10}
                ordering={(d) => -d.value.avg}
                filterKey="Lokasjon"
                useFlex
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Totalt antall timer i havn etter lokasjon (inntil 15 største)"
                dimension={dimLocation}
                group={GroupUtils.RemoveEmptyBinsTopN(
                  avgHoursByLocation,
                  15,
                  (a, b) => {
                    if (a.value.sum > b.value.sum) return -1;
                    if (b.value.sum > a.value.sum) return 1;
                    return 0;
                  }
                )}
                height={1.5}
                valueAccessor={(d) => Math.round(d.value.sum * 10) / 10}
                ordering={(d) => -d.value.sum}
                filterKey="Lokasjon"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <RowChart
                chartTitle="Antall ankomster etter lokasjon (inntil 10 største)"
                dimension={dimLocation}
                group={GroupUtils.RemoveEmptyBinsTopN(portCallsByLocation, 10)}
                useFlex
                height={1}
                valueAccessor={(d) => d.value}
                ordering={(d) => -d.value}
                filterKey="Lokasjon"
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Ankomster etter skipskategori "
                dimension={dimShipCategory}
                group={arrivalsByShipCategory}
                useFlex
                height={1}
                filterKey="Skipskategori"
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Ankomster etter skipstype (inntil 10 største)"
                dimension={dimShipType}
                group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByShipType, 10)}
                useFlex
                height={1}
                filterKey="Skipstype"
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Tid i havn for ankomster etter skip, eta, etd og lokasjon"
              dimension={dimId}
              sortBy={(d) => d.eta}
              useFlex
              size={Infinity}
              columns={[
                {
                  label: "#",
                  format: (d) => d.id,
                },
                {
                  label: "Skipsnavn",
                  format: (d) => d.shipname,
                },
                {
                  label: "Skipskategori",
                  format: (d) => d.shipcategory,
                },
                {
                  label: "Skipstype",
                  format: (d) => d.shiptype,
                },
                {
                  label: "ETA",
                  format: (d) => TimeUtils.toCompactTimestring(d.eta),
                  value: (d) => d.eta,
                },
                {
                  label: "ETD",
                  format: (d) => TimeUtils.toCompactTimestring(d.etd),
                  value: (d) => d.etd,
                },
                {
                  label: "Tid i havn",
                  format: (d) => d.hours,
                },
                {
                  label: "Lokasjon",
                  format: (d) => d.name,
                },
                {
                  label: "Type lokasjon",
                  format: (d) => d.type,
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdTimeInPortAllLocations = withRouter(
  DbrdTimeInPortAllLocationsBase
);

export default DbrdTimeInPortAllLocations;
