import React, { PropsWithChildren, useRef } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "react-bootstrap";

export type AVITileHeaderProps = PropsWithChildren & {
  title: string;
  info?: string;
  description?: string;
  resetFilter?: (e: any) => void;
  toggleLegend?: (e: any) => void;
};

export function AVITileHeader({
  title,
  info,
  children,
  resetFilter,
  toggleLegend,
}: AVITileHeaderProps) {

  const tileRef = useRef<any>();
  return (
    <div className="d-flex w-100" ref={tileRef}>
      <div className="kyv--text-body kyv--fs-body" onClick={()=>tileRef.current.scrollIntoView()}>
        {title}
        {info && (
          <Button
            as="a"
            title={info}
            className="ms-auto"
            variant="outline-primary"
          >
            <i className="bi bi-info-circle"></i>
          </Button>
        )}
      </div>
      <div className="ms-auto">
        <ButtonGroup>
          {resetFilter && (
            <Button variant="link-primary" onClick={resetFilter}>
              <i className="bi bi-arrow-return-left"></i>
            </Button>
          )}
          {toggleLegend && (
            <Button variant="link-primary" onClick={toggleLegend}>
              <i className="bi bi-list-ul"></i>
            </Button>
          )}
          {children && (
            <Dropdown>
              <DropdownToggle variant="link-primary kyv--no-caret">
                <i className="bi bi-three-dots-vertical"></i>
              </DropdownToggle>
              <DropdownMenu>{children}</DropdownMenu>
            </Dropdown>
          )}
        </ButtonGroup>
      </div>
    </div>
  );
}
